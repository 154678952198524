import React, { Suspense } from 'react';
import type { BarcodeScannerProps } from './BarcodeReaderImplementation';

const BarcodeReaderImplementation = React.lazy(() => {
  return new Promise((resolve) => {
    // Load the SDK script
    const script = document.createElement('script');
    script.src =
      'https://cdn.jsdelivr.net/npm/@pixelverse/strichjs-sdk@1.7.1/dist/strich-noesm.js';
    script.async = true;

    script.onload = async () => {
      console.log('STRICH onload SDK');
      const component = await import('./BarcodeReaderImplementation');
      resolve(component);
    };

    document.body.appendChild(script);
  });
});

const BarcodeReaderComponent = React.forwardRef<any, BarcodeScannerProps>(
  (props, ref) => {
    return (
      <Suspense fallback={<div>Loading scanner...</div>}>
        <BarcodeReaderImplementation {...props} ref={ref} />
      </Suspense>
    );
  }
);

export default BarcodeReaderComponent;
