import { OPEN_VERSION_MODAL, CLOSE_VERSION_MODAL } from "Constants/redux";

const INITIAL_STATE = {
  version: process.env.REACT_APP_VERSION,
  open: false,
  last_triggered: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_VERSION_MODAL:
      return {
        ...state,
        open: true,
        last_triggered: new Date().getTime()
      };
    case CLOSE_VERSION_MODAL:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};
