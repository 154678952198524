export default class PricingService {
  // V10042024

  static TIME_BASED_UNITS = [
    'hourly',
    'half_day',
    'daily',
    'weekly',
    'monthly',
  ];
  static VALUE_LINES = [
    'baseValue',
    'discountValue',
    'feeValue',
    'finalValue',
    'taxableValue',
    'damageWaiverAppliableValue',
  ];
  static FLAT_PERIODS = ['flat_price', 'flat_unit_price', 'edited_flat_price'];
  static TIME_UNIT_CONVERSION_FACTORS = {
    hourly: 1,
    half_day: 12,
    daily: 24,
    weekly: 168,
    monthly: 720,
  };
  static TIME_UNIT_NAMES = {
    hourly: 'hours',
    half_day: 'half days',
    daily: 'days',
    weekly: 'weeks',
    monthly: 'months',
  };
  static TIME_UNIT_DISPLAY = {
    hourly: 'Hour',
    half_day: 'Half-Day',
    daily: 'Day',
    weekly: 'Week',
    monthly: 'Month',
  };

  constructor(rentalObject) {
    this.rentalObject = rentalObject;
    this.itemDiscounts = [];
    this.itemFees = [];
    this.billingLines = [];
  }

  billingLinesObject = () => {
    const object = {};

    this.billingLinesForRental();

    this.billingLines
      .filter((line) => line.aggregate)
      .forEach((line) => {
        object[line.kind] = line;
      });

    return object;
  };

  billingLinesForRental = () => {
    this.billableItemsOfType('RentalItem').forEach(
      (rentalItemObject, index) => {
        this.billingLines.push({
          tag: `1.1.1.${index + 1}`,
          name: rentalItemObject.name,
          kind: 'rentalItemStandardLine',
          rentalItemObject,
          inventoryObject: rentalItemObject,
          aggregate: false,
          ...this.calculateRentalItemPricing(rentalItemObject),
        });
      }
    );

    this.billableItemsOfType('RentalItemTemporary').forEach(
      (temporaryItemObject, index) => {
        this.billingLines.push({
          tag: `1.1.2.${index + 1}`,
          name: temporaryItemObject.name,
          kind: 'rentalItemTemporaryLine',
          temporaryItemObject,
          inventoryObject: temporaryItemObject,
          aggregate: false,
          ...this.calculateRentalItemPricing(temporaryItemObject),
        });
      }
    );

    this.buildAggregateBillingLine('1.1.1', 'Rental Item Standard Total');
    this.buildAggregateBillingLine('1.1.2', 'Rental Item Temporary Total');
    this.buildAggregateBillingLine('1.1', 'Rental Item Total');

    this.billableItemsOfType('RentalAddOn').forEach(
      (rentalAddOnObject, index) => {
        this.billingLines.push({
          tag: `1.2.${index + 1}`,
          name: rentalAddOnObject.name,
          kind: 'rentalAddOnLine',
          rentalAddOnObject,
          inventoryObject: rentalAddOnObject,
          aggregate: false,
          ...this.calculateAddOnPricing(rentalAddOnObject),
        });
      }
    );

    this.buildAggregateBillingLine('1.2', 'Purchase Total');

    this.billableItemsOfType('RentalBundle').forEach((bundleObject, index) => {
      this.billingLines.push({
        tag: `1.3.${index + 1}`,
        name: bundleObject.name,
        kind: 'bundleLine',
        bundleObject,
        inventoryObject: bundleObject,
        aggregate: false,
        ...this.calculateBundlePricing(bundleObject),
      });
    });
    this.buildAggregateBillingLine('1.3', 'Bundle Total');

    this.buildAggregateBillingLine('1', 'Inventory Total');

    this.billableItemsOfType('RentalStaff').forEach((staffObject, index) => {
      this.billingLines.push({
        tag: `2.${index + 1}`,
        name: staffObject.name,
        kind: 'staffLine',
        aggregate: false,
        ...this.calculateStaffPricing(staffObject),
        inventoryObject: staffObject,
      });
    });

    this.buildAggregateBillingLine('2', 'Staff Total');

    this.calculateDeliveryPricing();
    this.buildAggregateBillingLine('3', 'Delivery Total');

    this.calculateGlobalDiscounts();
    this.buildAggregateBillingLine('4', 'Global Discounts Total');

    this.calculateGlobalFees();
    this.buildAggregateBillingLine('5.1', 'Standard Fees Total');
    this.buildAggregateBillingLine('5.2', 'Damage Waiver Fee Total');
    this.buildAggregateBillingLine('5.3', 'Damage Fees Total');
    this.buildAggregateBillingLine('5.3.1', 'Item Damage Fees Total');
    this.buildAggregateBillingLine('5.3.2', 'Accessory Damage Fees Total');
    this.buildAggregateBillingLine('5', 'Global Fees Total');

    this.buildAggregateBillingLine('6', 'Subtotal', true);

    this.calculateSalesTaxes();
    this.buildAggregateBillingLine('7', 'Tax Total');
    this.buildAggregateBillingLine('7.1', 'Sales Taxes Total');
    this.buildAggregateBillingLine('7.2', 'Additional Taxes Total');

    this.calculateCreditCardFee();
    this.buildAggregateBillingLine('8', 'Credit Card Fee Total');

    this.buildAggregateBillingLine('9', 'Order Total', true);

    if (
      this.rentalObject.source === 'multilocation' &&
      this.rentalObject.no_cost_subrental_transfer
    ) {
      this.billingLines.forEach((line) => (line.finalValue = 0));
    }

    return this.accountingSort(this.billingLines);
  };

  buildAggregateBillingLine = (tag, name, useAllPreviousLines = false) => {
    const aggregatedLines = this.billingLines.filter(
      (line) =>
        !line.aggregate &&
        (line.tag.startsWith(`${tag}.`) || useAllPreviousLines)
    );
    this.billingLines.push({
      tag: tag,
      name: name,
      kind: this.camelize(name),
      aggregate: true,
      aggregatedLines,
      ...this.aggregateBillingLineRoundedValues(aggregatedLines),
    });
  };

  aggregateBillingLineRoundedValues = (aggregatedLines) => {
    return Object.fromEntries(
      PricingService.VALUE_LINES.map((valueLine) => [
        valueLine,
        Math.round(
          aggregatedLines.reduce(
            (acc, line) => acc + Number(line[valueLine] || 0),
            0
          ) * 100
        ) / 100,
      ])
    );
  };

  // const [discountValue, discountExplanation, discountLines] =
  //     this.discountDetailsForItem(rentalItemObject, baseValue);
  //   const [feeValue, feeExplanation, feeLines] = this.feeDetailsForItem(
  //     rentalItemObject,
  //     baseValue
  //   );

  //   const finalValue =
  //     Math.round((baseValue + feeValue + discountValue) * 100) / 100;
  //   const taxableValue = rentalItemObject.tax_exempt ? 0 : finalValue;
  //   const damageWaiverAppliableValue =
  //     rentalItemObject.damage_waiver_exempt ||
  //     rentalItemObject.type === 'RentalItemTemporary'
  //       ? 0
  //       : baseValue;

  //   return {
  //     baseValue,
  //     finalValue,
  //     discountValue,
  //     feeValue,
  //     taxableValue,
  //     damageWaiverAppliableValue,
  //     baseValueExplanation,
  //     discountExplanation,
  //     feeExplanation,
  //     discountLines,
  //     feeLines,
  //     priceDisplay,
  //     period: rentalItemObject.period,
  //     rvId: rentalItemObject.rv_id,
  //   };
  // };

  calculateRentalItemPricing = (rentalItemObject) => {
    let baseValue;
    let baseValueExplanation;
    let priceDisplay;

    [baseValue, baseValueExplanation, priceDisplay] =
      this.calculateBaseValueForRentalItem(rentalItemObject);

    const [discountValue, discountExplanation, discountLines] =
      this.discountDetailsForItem(rentalItemObject, baseValue);

    const [feeValue, feeExplanation, feeLines, taxableFeeValue] =
      this.feeDetailsForItem(rentalItemObject, baseValue);

    const finalValue =
      Math.round((baseValue + feeValue + discountValue) * 100) / 100;
    const taxableValue = rentalItemObject.tax_exempt
      ? 0
      : Math.round((baseValue + taxableFeeValue + discountValue) * 100) / 100;
    const damageWaiverAppliableValue =
      rentalItemObject.damage_waiver_exempt ||
      rentalItemObject.type === 'RentalItemTemporary'
        ? 0
        : baseValue;

    return {
      baseValue,
      finalValue,
      discountValue,
      feeValue,
      taxableValue,
      damageWaiverAppliableValue,
      baseValueExplanation,
      discountExplanation,
      feeExplanation,
      discountLines,
      feeLines,
      priceDisplay,
      period: rentalItemObject.period,
      rvId: rentalItemObject.rv_id,
    };
  };

  calculateBaseValueForRentalItem = (rentalItemObject) => {
    let baseValue;
    let roundedDurationInPrimaryUnit;
    let secondaryUnit;
    let roundedDurationInSecondaryUnit;
    let baseValueExplanation;
    let priceDisplay;
    const durationInHoursForRentalItemObject =
      this.durationInHours(rentalItemObject);

    if (rentalItemObject.period.includes('standard_flat_price')) {
      // When we use standard_flat_price, we pass the name of the flat price used between brackets on the period. The following regex extracts that flat price name
      const flatPriceName = rentalItemObject.period.match(/\[(.*?)\]/)[1];
      const flatPriceAttributes = (
        rentalItemObject.flat_prices_attributes || []
      ).find((flatPrice) => flatPrice.name === flatPriceName && flatPrice._destroy !== '1');

      baseValue = flatPriceAttributes.amount * rentalItemObject.quantity;
      baseValueExplanation = `This item is using the flat price "${flatPriceAttributes.name}" of $${flatPriceAttributes.amount} per item and a quantity of ${rentalItemObject.quantity} for a total of $${baseValue}`;
      priceDisplay = `${this.formatPrice(
        flatPriceAttributes.amount
      )}/Flat: ${flatPriceName}`;
    } else if (
      ['edited_flat_unit_price', 'flat_unit_price'].includes(
        rentalItemObject.period
      )
    ) {
      baseValue =
        Math.round(
          Number(
            rentalItemObject.quantity *
              (rentalItemObject.edited_flat_unit_price != null
                ? rentalItemObject.edited_flat_unit_price
                : rentalItemObject.selected_price_before_discount)
          ) * 100
        ) / 100;
      baseValueExplanation = `This item is using a defined unit price of $${
        rentalItemObject.edited_flat_unit_price != null
          ? rentalItemObject.edited_flat_unit_price
          : rentalItemObject.selected_price_before_discount
      } per item and a quantity of ${
        rentalItemObject.quantity
      } for a total of $${baseValue}`;
      priceDisplay = `${this.formatPrice(
        rentalItemObject.edited_flat_unit_price != null
          ? rentalItemObject.edited_flat_unit_price
          : rentalItemObject.selected_price_before_discount
      )}/each`;
    } else if (
      ['flat_price', 'edited_flat_price'].includes(rentalItemObject.period)
    ) {
      baseValue =
        Math.round(
          Number(
            rentalItemObject.edited_flat_price != null
              ? rentalItemObject.edited_flat_price
              : rentalItemObject.selected_price_before_discount
          ) * 100
        ) / 100;
      baseValueExplanation = `This item is using a defined total price of $${baseValue}`;
      priceDisplay = `${this.formatPrice(
        baseValue / rentalItemObject.quantity
      )}/each`;
    } else if (rentalItemObject.period === 'advanced_pricing') {
      baseValue =
        Math.round(rentalItemObject.selected_price_before_discount * 100) / 100;
      baseValueExplanation = `This item is used advanced pricing. For this order duration, the advanced pricing configuration of this location is set as ${
        rentalItemObject.advanced_pricing_primary_multiplier
      } x ${rentalItemObject.advanced_pricing_primary_rate} ${
        rentalItemObject.advanced_pricing_secondary_rate
          ? ` + ${rentalItemObject.advanced_pricing_secondary_multiplier} x ${rentalItemObject.advanced_pricing_secondary_rate}`
          : ''
      }. For this item, this means ${
        rentalItemObject.advanced_pricing_primary_multiplier
      } x ${
        rentalItemObject[
          `${rentalItemObject.advanced_pricing_primary_rate}_price`
        ]
      } ${
        rentalItemObject.advanced_pricing_secondary_rate
          ? ` + ${rentalItemObject.advanced_pricing_secondary_multiplier} x ${
              rentalItemObject[
                `${rentalItemObject.advanced_pricing_secondary_rate}_price`
              ]
            }`
          : ''
      } = $${baseValue}`;
    } else {
      // Time-based pricing
      PricingService.TIME_UNIT_CONVERSION_FACTORS.monthly =
        // this.calculateHoursFromMonths(rentalItemObject);
        this.calculateAverageHoursOnCoveredMonths();
      let primaryUnit = rentalItemObject.period
        .split('_')
        .slice(0, -1)
        .join('_');
      if (
        rentalItemObject[`${primaryUnit}_price`] === null ||
        rentalItemObject[`${primaryUnit}_price`] === undefined
      )
        primaryUnit = this.defaultTimeBasedPeriodForItem(rentalItemObject);

      const possibleSecondaryUnits = this.rentalObject.smart_pricing_active
        ? this.possibleSecondaryUnits(primaryUnit).filter(
            (unit) => rentalItemObject[`${unit}_price`]
          )
        : [];

      const roundingFunctionForPrimaryUnit = this.rentalObject
        .smart_pricing_active
        ? possibleSecondaryUnits.length === 0
          ? Math.ceil
          : Math.floor
        : Math.round;

      roundedDurationInPrimaryUnit = roundingFunctionForPrimaryUnit(
        this.durationInGivenUnit(
          durationInHoursForRentalItemObject,
          primaryUnit
        )
      );
      baseValue =
        Math.round(
          rentalItemObject[`${primaryUnit}_price`] *
            roundedDurationInPrimaryUnit *
            rentalItemObject.quantity *
            100
        ) / 100;

      priceDisplay = `${this.formatPrice(
        rentalItemObject[`${primaryUnit}_price`]
      )}/${PricingService.TIME_UNIT_DISPLAY[primaryUnit]}`;

      const remainingDurationInHours =
        durationInHoursForRentalItemObject -
        roundedDurationInPrimaryUnit *
          PricingService.TIME_UNIT_CONVERSION_FACTORS[primaryUnit];
      secondaryUnit =
        possibleSecondaryUnits.find(
          (unit) =>
            PricingService.TIME_UNIT_CONVERSION_FACTORS[unit] <=
            remainingDurationInHours
        ) || possibleSecondaryUnits[0];
      if (remainingDurationInHours > 0 && secondaryUnit) {
        roundedDurationInSecondaryUnit = Math.ceil(
          this.durationInGivenUnit(remainingDurationInHours, secondaryUnit)
        );
        baseValue +=
          Math.round(
            rentalItemObject[`${secondaryUnit}_price`] *
              roundedDurationInSecondaryUnit *
              rentalItemObject.quantity *
              100
          ) / 100;
      }

      if (secondaryUnit) {
        baseValueExplanation = `This item is priced according to time-based pricing rules using combined-rate pricing, which means it uses a combination of two price rates to reach the item value. This item is priced for a duration of ${durationInHoursForRentalItemObject} hours. Since this item has prices set for (${this.availablePriceNames(
          rentalItemObject
        )}) rates, the best combination found is ${roundedDurationInPrimaryUnit} x ${primaryUnit} rate + ${roundedDurationInSecondaryUnit} x ${secondaryUnit} rate. Therefore, the base value for this item is (${roundedDurationInPrimaryUnit} x $${
          rentalItemObject[`${primaryUnit}_price`]
        } + ${roundedDurationInSecondaryUnit} x $${
          rentalItemObject[`${secondaryUnit}_price`]
        }) x ${
          rentalItemObject.quantity
        } units = $${baseValue} after rounding to two decimals.`;
      } else {
        baseValueExplanation = `This item is priced according to time-based pricing rules using the ${primaryUnit} price. The rounded duration of the rental in ${
          PricingService.TIME_UNIT_NAMES[primaryUnit]
        } is ${roundedDurationInPrimaryUnit} and the ${primaryUnit} price for the item is $${
          rentalItemObject[`${primaryUnit}_price`]
        }. Therefore, the base value for this item $${
          rentalItemObject[`${primaryUnit}_price`]
        } x ${roundedDurationInPrimaryUnit} x ${
          rentalItemObject.quantity
        } units = $${baseValue} after rounding to two decimals.`;
      }
      if (
        this.rentalObject.smart_pricing_active &&
        this.availablePriceNames(rentalItemObject).length === 1
      )
        baseValueExplanation +=
          ' Combined-rate pricing is not active for this item because it only has one price rate set.';
      if (
        this.rentalObject.smart_pricing_active &&
        this.availablePriceNames(rentalItemObject).length > 1 &&
        possibleSecondaryUnits.length === 0
      )
        baseValueExplanation += ` Combined-rate pricing is not active for this item because the other prices set for this item (${this.availablePriceNames(
          rentalItemObject
        ).filter(
          (unit) => unit !== PricingService.TIME_UNIT_NAMES[primaryUnit]
        )}) are bigger or more than two tiers away from the primary price unit of ${
          PricingService.TIME_UNIT_NAMES[primaryUnit]
        }.`;
    }

    return [baseValue, baseValueExplanation, priceDisplay];
  };

  calculateHoursFromMonths = (entity = undefined) => {
    // The version of Node inside Ruby container doesn't support optional chaining (?.)
    let startDateTime;
    let endDateTime;

    if (entity && entity.start_date_time && entity.end_date_time) {
      startDateTime = new Date(entity.start_date_time);
      endDateTime = new Date(entity.end_date_time);
    } else {
      startDateTime = new Date(
        this.rentalObject.schedule_attributes.event_start_date_time
      );
      endDateTime = new Date(
        this.rentalObject.schedule_attributes.event_end_date_time
      );
    }

    if (!startDateTime || !endDateTime)
      return PricingService.TIME_UNIT_CONVERSION_FACTORS.monthly;

    let months = 0;
    let monthsTimeInHours = 0;
    let currentDateTime = new Date(startDateTime);

    while (currentDateTime <= endDateTime) {
      monthsTimeInHours += this.hoursOnMonth(currentDateTime);
      months++;
      currentDateTime.setMonth(currentDateTime.getMonth() + 1);
    }

    return monthsTimeInHours / months;
  };

  calculateAverageHoursOnCoveredMonths = (entity = undefined) => {
    if (!this.rentalObject || !this.rentalObject.schedule_attributes) {
      return PricingService.TIME_UNIT_CONVERSION_FACTORS.monthly;
    }

    let startDateTime;
    let endDateTime;

    if (entity && entity.start_date_time && entity.end_date_time) {
      startDateTime = new Date(entity.start_date_time);
      endDateTime = new Date(entity.end_date_time);
    } else {
      startDateTime = new Date(
        this.rentalObject.schedule_attributes.event_start_date_time
      );
      endDateTime = new Date(
        this.rentalObject.schedule_attributes.event_end_date_time
      );
    }

    // TODO: Review with Felipe if this is a correct implementation of the entity parameter
    // const startDateTime = new Date(
    //   this.rentalObject.schedule_attributes.event_start_date_time
    // );
    // const endDateTime = new Date(
    //   this.rentalObject.schedule_attributes.event_end_date_time
    // );

    if (!startDateTime || !endDateTime)
      return PricingService.TIME_UNIT_CONVERSION_FACTORS.monthly;

    const coveredMonthsInSchedule = this.coveredMonths(
      startDateTime,
      endDateTime
    );

    const hoursOnCoveredMonths = coveredMonthsInSchedule.map((month) => {
      const monthDate = new Date(startDateTime.getFullYear(), month - 1, 1);
      const hoursInMonth = this.hoursOnMonth(monthDate);
      return hoursInMonth;
    });

    return (
      hoursOnCoveredMonths.reduce((a, b) => a + b, 0) /
      coveredMonthsInSchedule.length
    );
  };

  coveredMonths = (startDateTime, endDateTime) => {
    const currentDate = startDateTime;
    const months = [currentDate.getMonth() + 1];
    currentDate.setMonth(currentDate.getMonth() + 1);

    while (currentDate.getTime() <= endDateTime.getTime()) {
      const currentMonth = currentDate.getMonth() + 1;
      const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentMonth,
        0
      );

      if (lastDayOfMonth <= endDateTime) months.push(currentMonth);

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
  };

  hoursOnMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const firstDayOfNextMonth = new Date(year, month + 1, 1);
    const firstDayOfCurrentMonth = new Date(year, month, 1);
    const millisecondsDiff = firstDayOfNextMonth - firstDayOfCurrentMonth;

    const hoursInMonth = millisecondsDiff / (1000 * 60 * 60);

    return hoursInMonth;
  };

  defaultTimeBasedPeriodForItem = (item) => {
    let primaryUnit;
    const baseUnit = this.basePriceUnitForTimeBasedPricing();
    const availablePricesForItem = PricingService.TIME_BASED_UNITS.filter(
      (unit) => item[`${unit}_price`] != null
    );

    if (availablePricesForItem.includes(baseUnit)) {
      primaryUnit = baseUnit;
    } else {
      const unitsBelowBaseUnit = PricingService.TIME_BASED_UNITS.slice(
        0,
        PricingService.TIME_BASED_UNITS.indexOf(baseUnit)
      ).reverse();
      const unitsAboveBaseUnit = PricingService.TIME_BASED_UNITS.slice(
        PricingService.TIME_BASED_UNITS.indexOf(baseUnit) + 1
      );
      primaryUnit =
        unitsBelowBaseUnit.find((unit) =>
          availablePricesForItem.includes(unit)
        ) ||
        unitsAboveBaseUnit.find((unit) =>
          availablePricesForItem.includes(unit)
        );
    }

    return `${primaryUnit}_price`;
  };

  calculateAddOnPricing = (addOnObject) => {
    // Price per unit does not exist for legacy rentals, so we use the selected price divided by the quantity fallback for pricing simulations of how V1 rentals would behave on V2
    const pricePerUnit =
      addOnObject.price_per_unit ||
      Number(addOnObject.selected_price) / addOnObject.quantity;

    const baseValue = pricePerUnit * addOnObject.quantity;
    const baseValueExplanation = `This item has a price of $${pricePerUnit} per unit and a quantity of ${addOnObject.quantity} for a total of $${baseValue}.`;
    const priceDisplay = `${this.formatPrice(pricePerUnit)}/each`;

    return {
      pricePerUnit,
      baseValue: baseValue,
      finalValue: baseValue,
      damageWaiverAppliableValue: 0,
      taxableValue: addOnObject.tax_exempt ? 0 : baseValue,
      discountValue: 0,
      feeValue: 0,
      priceDisplay,
      baseValueExplanation,
      rvId: addOnObject.rv_id,
    };
  };

  calculateBundlePricing = (bundleObject) => {
    let baseValue;
    let baseValueExplanation;
    let priceDisplay;
    let damageWaiverAppliableValue = 0;

    const rentalItemsBillingLines = [];

    if (bundleObject.price_locked) {
      bundleObject.selected_price_before_discount = bundleObject.selected_price;
      const pricingLine = this.calculateRentalItemPricing(bundleObject);
      baseValue = pricingLine.baseValue;
      priceDisplay = pricingLine.priceDisplay;
      baseValueExplanation = `This bundle is price-locked and therefore the value is calculated following the same rules as a rental item: ${pricingLine.baseValueExplanation}`;
      damageWaiverAppliableValue = bundleObject.damage_waiver_exempt
        ? 0
        : baseValue;
    } else {
      baseValueExplanation =
        'This bundle is not price-locked and therefore the value is calculated by summing the base values of the items in the bundle. The items values included in this bundle are:';
      priceDisplay = this.calculateRentalItemPricing(bundleObject).priceDisplay;

      baseValue = [...bundleObject.rental_items_attributes]
        .filter((ri) => ri._destroy != '1')
        .reduce((acc, rentalItem) => {
          const pricingLine = this.calculateRentalItemPricing(rentalItem);

          pricingLine.productId = rentalItem.product_id;

          const itemBaseValueAfterDiscounts =
            pricingLine.baseValue * (1 - Number(bundleObject.discount_percent));
          baseValueExplanation += ` ${rentalItem.name} (Base value of $${
            pricingLine.baseValue
          } with a ${
            bundleObject.discount_percent * 100
          }% discount totalling a base value of $${itemBaseValueAfterDiscounts});`;

          pricingLine.baseValue = pricingLine.finalValue =
            itemBaseValueAfterDiscounts;

          damageWaiverAppliableValue +=
            rentalItem.damage_waiver_exempt || bundleObject.damage_waiver_exempt
              ? 0
              : itemBaseValueAfterDiscounts;

          rentalItemsBillingLines.push(pricingLine);

          return acc + itemBaseValueAfterDiscounts;
        }, 0);
    }

    if (
      PricingService.TIME_BASED_UNITS.some(
        (unit) => `${unit}_price` === bundleObject.period
      )
    ) {
      const purchasePriceAddedValue =
        bundleObject.purchase_price * bundleObject.quantity;

      baseValue += purchasePriceAddedValue;
      if (purchasePriceAddedValue > 0) {
        baseValueExplanation += ` This bundle has purchase fee of $${bundleObject.purchase_price} per unit and the quantity is ${bundleObject.quantity} for a total of $${purchasePriceAddedValue}.`;
      }
    }

    baseValue = Math.round(baseValue * 100) / 100;
    baseValueExplanation += ` All items are then summed and the value is rounded to the nearest cent, totalling $${baseValue}.`;

    const [discountValue, discountExplanation, discountLines] =
      this.discountDetailsForItem(bundleObject, baseValue);
    const [feeValue, feeExplanation, feeLines] = this.feeDetailsForItem(
      bundleObject,
      baseValue
    );

    const finalValue =
      Math.round((baseValue + feeValue + discountValue) * 100) / 100;
    let taxableValue = 0;
    if (bundleObject.tax_exemption_setting === 'no_items_tax_exempt') {
      taxableValue = finalValue;
    } else if (bundleObject.tax_exemption_setting === 'all_items_tax_exempt') {
      taxableValue = 0;
    } else if (
      bundleObject.tax_exemption_setting ===
      'defer_tax_exempt_status_to_children'
    ) {
      taxableValue += [...bundleObject.rental_items_attributes].reduce(
        (acc, rentalItem) => {
          const pricingLine = this.calculateRentalItemPricing(rentalItem);
          const itemTaxableValueAfterDiscounts =
            pricingLine.taxableValue *
            (1 - Number(bundleObject.discount_percent));
          return acc + itemTaxableValueAfterDiscounts;
        },
        0
      );
      taxableValue += [...bundleObject.rental_add_ons_attributes].reduce(
        (acc, rentalAddOn) => {
          const pricingLine = this.calculateAddOnPricing(rentalAddOn);
          const itemTaxableValueAfterDiscounts =
            pricingLine.taxableValue *
            (1 - Number(bundleObject.discount_percent));
          return acc + itemTaxableValueAfterDiscounts;
        },
        0
      );
    }

    return {
      baseValue,
      finalValue,
      discountValue,
      feeValue,
      taxableValue,
      damageWaiverAppliableValue,
      baseValueExplanation,
      discountExplanation,
      feeExplanation,
      discountLines,
      feeLines,
      priceDisplay,
      period: bundleObject.period,
      rvId: bundleObject.rv_id,
      rentalItemsBillingLines,
      rentalAddOnsBillingLines: [],
    };
  };

  discountDetailsForItem(rentalItemObject, baseValue) {
    const discountLines = [];
    let discountExplanation =
      'The following discounts were applied to this item: ';

    const filteredItemDiscounts = (
      rentalItemObject.rental_item_discount_relationships_attributes || []
    ).filter((d) => d._destroy !== '1');

    const filteredBundleDiscounts = (
      rentalItemObject.rental_bundle_discount_relationships_attributes || []
    ).filter((d) => d._destroy !== '1');

    const itemDiscountSum =
      [...filteredItemDiscounts, ...filteredBundleDiscounts].reduce(
        (acc, discount) => {
          const currentDiscountValue =
            discount.value_type === 'percentage' ||
            discount.value_type === 'percent'
              ? baseValue * Number(discount.amount)
              : Number(discount.amount * rentalItemObject.quantity);

          if (
            discount.value_type === 'percentage' ||
            discount.value_type === 'percent'
          ) {
            discountExplanation += `${discount.name} (Percentage of ${
              discount.amount * 100
            }% upon the base item value of ${baseValue}, totalling ${currentDiscountValue}); `;
          } else {
            discountExplanation += `${discount.name} (Fixed value of ${
              discount.amount
            } per unit, totalling ${
              discount.amount * rentalItemObject.quantity
            }); `;
          }

          discountLines.push({
            discountId: discount.discount_id,
            name: discount.name,
            value: currentDiscountValue,
          });

          return acc + currentDiscountValue;
        },
        0
      ) || 0;
    const discountValue = -Math.round(itemDiscountSum * 100) / 100;
    discountExplanation += ` All discounts are then summed and the discount value is rounded to the nearest cent, totalling ${discountValue}.`;
    if (discountValue === 0)
      discountExplanation = 'No item discounts were applied to this item.';

    return [discountValue, discountExplanation, discountLines];
  }

  feeDetailsForItem(rentalItemObject, baseValue) {
    const feeLines = [];
    let feeExplanation = 'The following fees were applied to this item: ';
    let taxableFeeValue = 0;

    const filteredItemFees = (
      rentalItemObject.rental_item_fee_relationships_attributes || []
    ).filter((fee) => fee._destroy !== '1');

    const filteredBundleFees = (
      rentalItemObject.rental_bundle_fee_relationships_attributes || []
    ).filter((fee) => fee._destroy !== '1');

    const itemFeeSum =
      [...filteredItemFees, ...filteredBundleFees].reduce((acc, fee) => {
        const currentFeeValue =
          fee.value_type === 'percentage' || fee.value_type === 'percent'
            ? baseValue * Number(fee.amount)
            : Number(fee.amount * rentalItemObject.quantity);

        if (fee.value_type === 'percentage' || fee.value_type === 'percent') {
          feeExplanation += `${fee.name} (Percentage of ${
            fee.amount * 100
          }% upon the base item value of ${baseValue}, totalling ${currentFeeValue}); `;
        } else {
          feeExplanation += `${fee.name} (Fixed value of ${
            fee.amount
          } per unit, totalling ${fee.amount * rentalItemObject.quantity}); `;
        }

        feeLines.push({
          feeId: fee.fee_id,
          name: fee.name,
          value: currentFeeValue,
        });
        taxableFeeValue += fee.tax_exempt ? 0 : currentFeeValue;

        return acc + currentFeeValue;
      }, 0) || 0;
    const feeValue = Math.round(itemFeeSum * 100) / 100;
    feeExplanation += ` All fees are then summed and the fee value is rounded to the nearest cent, totalling ${feeValue}.`;
    if (feeValue === 0)
      feeExplanation = 'No item fees were applied to this item.';

    return [feeValue, feeExplanation, feeLines, taxableFeeValue];
  }

  calculateStaffPricing = (staffObject) => {
    let priceDisplay;

    const timePricingTypes = [
      'hourly',
      'half_day',
      'daily',
      'weekly',
      'monthly',
    ];
    const selectedStaffPricing = (
      staffObject.staff_role_attributes.staff_pricings_attributes || []
    ).find((sfa) => Number(sfa.id) === staffObject.staff_pricing_id);
    let baseValue;
    if (
      selectedStaffPricing &&
      timePricingTypes.includes(selectedStaffPricing.pricing_type)
    ) {
      const roundedDurationInPrimaryUnit = Math.round(
        this.durationInGivenUnit(
          this.durationInHoursOfStaffSchedule(staffObject),
          selectedStaffPricing.pricing_type
        )
      );
      baseValue =
        Math.round(
          selectedStaffPricing.price *
            roundedDurationInPrimaryUnit *
            staffObject.size *
            100
        ) / 100;
      priceDisplay = `${this.formatPrice(selectedStaffPricing.price)}/${
        PricingService.TIME_UNIT_DISPLAY[selectedStaffPricing.pricing_type]
      }`;
    } else if (
      selectedStaffPricing &&
      selectedStaffPricing.pricing_type === 'custom'
    ) {
      baseValue =
        Math.round(selectedStaffPricing.price * staffObject.size * 100) / 100;
      priceDisplay = `${this.formatPrice(selectedStaffPricing.price)}/${
        selectedStaffPricing.custom_title
      }`;
    } else {
      baseValue = Number(staffObject.user_input_pricing);
      priceDisplay = 'Custom Price';
    }

    if (
      staffObject.recurrence_dates &&
      staffObject.recurrence_dates.length > 0
    ) {
      baseValue = baseValue * staffObject.recurrence_dates.length;
    }

    return {
      baseValue: baseValue,
      finalValue: baseValue,
      taxableValue: staffObject.staff_role_attributes.tax_exempt
        ? 0
        : baseValue,
      discountValue: 0,
      feeValue: 0,
      damageWaiverAppliableValue: 0,
      baseValueExplanation: `This staff member has a cost of $${baseValue}.`,
      priceDisplay,
      staffObject,
      rvId: staffObject.rv_id,
    };
  };

  calculateRentalItemAdvancedPricing = (rentalItemObject) => {};

  calculateDeliveryPricing = () => {
    const { delivery_type, delivery_cost } = this.rentalObject;

    if (delivery_type === 'custom_delivery' || delivery_type === 'ship') {
      this.billingLines.push({
        tag: '3.1',
        kind: 'deliveryFee',
        name: 'Delivery Fee',
        aggregate: false,
        baseValue: delivery_cost,
        finalValue: delivery_cost,
        discountValue: 0,
        feeValue: 0,
        damageWaiverAppliableValue: 0,
        taxableValue: this.rentalObject.delivery_tax_enabled
          ? delivery_cost
          : 0,
        baseValueExplanation: `This rental has a custom delivery fee of $${delivery_cost}.`,
      });
      return;
    }

    let baseValueExplanation = '';
    let deliveryCostDueToMileageFee;
    let deliveryCostDueToPercentOfSales;

    const deliverySettings = this.rentalObject.delivery_setting_attributes;

    if (!deliverySettings) {
      this.billingLines.push({
        tag: '3.1',
        kind: 'deliveryFee',
        name: 'Delivery Fee',
        aggregate: false,
        baseValue: 0,
        finalValue: 0,
        discountValue: 0,
        feeValue: 0,
        damageWaiverAppliableValue: 0,
        taxableValue: 0,
        baseValueExplanation: "This rental doesn't have a delivery fee.",
      });
      return;
    }

    const deliveryCostDueToFlatFee =
      Math.round(Number(deliverySettings.flat_fee || 0) * 100) / 100;
    let baseValue = deliveryCostDueToFlatFee;
    if (deliveryCostDueToFlatFee > 0)
      baseValueExplanation += `This delivery has a flat fee of $${deliveryCostDueToFlatFee}. `;

    if (['default_delivery'].includes(this.rentalObject.delivery_type)) {
      const distanceInMiles = this.rentalObject.delivery_distance_in_miles;
      const chargeableDistance =
        distanceInMiles -
        Number(deliverySettings.minimum_miles_till_charge || 0);
      if (chargeableDistance > 0) {
        deliveryCostDueToMileageFee =
          Math.round(
            chargeableDistance *
              Number(deliverySettings.mileage_fee || 0) *
              10000
          ) / 10000;
        baseValue += deliveryCostDueToMileageFee;
        baseValueExplanation += `This delivery has a charge of $${
          deliverySettings.mileage_fee
        } per mile exceeding ${
          deliverySettings.minimum_miles_till_charge
        } miles. The delivery distance is ${
          Math.round(distanceInMiles * 1000) / 1000
        } and the chargeable distance is ${
          Math.round(chargeableDistance * 1000) / 1000
        }. The delivery mileage fee is therefore ${
          Math.round(chargeableDistance * 1000) / 1000
        } x $${
          deliverySettings.mileage_fee
        } = $${deliveryCostDueToMileageFee}.`;
      }
      if (deliverySettings.percent_of_sales > 0) {
        deliveryCostDueToPercentOfSales =
          Math.round(
            Number(deliverySettings.percent_of_sales / 100) *
              this.findBillingLine('Inventory Total').finalValue *
              10000
          ) / 10000;
        baseValue += deliveryCostDueToPercentOfSales;
        baseValueExplanation += ` This delivery has a charge of ${
          deliverySettings.percent_of_sales
        }% of the inventory total value. The inventory total value is $${
          this.findBillingLine('Inventory Total').finalValue
        } and the delivery percent of sales fee is therefore $${
          deliverySettings.percent_of_sales
        }% of $${
          this.findBillingLine('Inventory Total').finalValue
        } = $${deliveryCostDueToPercentOfSales}.`;
      }
    } else {
      baseValue = 0;
      baseValueExplanation = 'This rental is not being delivered.';
    }
    baseValue = Math.round(baseValue * 100) / 100;
    if (baseValue > 0)
      baseValueExplanation += ` The total delivery fee is therefore ${[
        deliveryCostDueToFlatFee,
        deliveryCostDueToMileageFee,
        deliveryCostDueToPercentOfSales,
      ]
        .filter((val) => val > 0)
        .map((val) => `$${val}`)
        .join(
          ' + '
        )} = $${baseValue} after rounding the final sum to two decimals..`;

    this.billingLines.push({
      tag: '3.1',
      kind: 'deliveryFee',
      name: 'Delivery Fee',
      aggregate: false,
      baseValue: baseValue,
      finalValue: baseValue,
      discountValue: 0,
      feeValue: 0,
      damageWaiverAppliableValue: 0,
      taxableValue: this.rentalObject.delivery_tax_enabled ? baseValue : 0,
      baseValueExplanation,
    });
  };

  calculateGlobalDiscounts = () => {
    const filteredRentalDiscounts = (
      this.rentalObject.discount_rental_relationships_attributes || []
    ).filter((d) => d._destroy !== '1' && d.discount_target_type === 'global');

    filteredRentalDiscounts.forEach((globalDiscount, index) => {
      const baseValue =
        -Math.round(
          (['percentage', 'percent'].includes(globalDiscount.value_type)
            ? this.findBillingLine('Inventory Total').finalValue *
              Number(globalDiscount.amount)
            : Number(globalDiscount.amount)) * 100
        ) / 100;
      const baseValueExplanation = ['percentage', 'percent'].includes(
        globalDiscount.value_type
      )
        ? `This discount amounts to ${
            Number(globalDiscount.amount) * 100
          }% of the total inventory value of ${
            this.findBillingLine('Inventory Total').finalValue
          }, totalling $${baseValue}  after rounding to two decimals`
        : `This discount is a fixed discount of $${baseValue}.`;
      this.billingLines.push({
        tag: `4.${index + 1}`,
        kind: 'globalDiscountLine',
        name: globalDiscount.name,
        aggregate: false,
        baseValue: baseValue,
        finalValue: baseValue,
        taxableValue: baseValue,
        discountValue: 0,
        feeValue: 0,
        damageWaiverAppliableValue: 0,
        baseValueExplanation,
      });
    });
  };

  calculateCreditCardFee = () => {
    const amount = (this.rentalObject.fee_rental_relationships_attributes || [])
      .filter(
        (fee) => fee._destroy !== '1' && fee.fee_type === 'credit_card_fee'
      )
      .reduce((acc, fee) => acc + Number(fee.amount) || 0.0, 0.0);

    this.billingLines.push({
      tag: '8.1',
      kind: 'creditCardFee',
      name: 'Credit Card Fee',
      aggregate: false,
      baseValue: amount,
      finalValue: amount,
      discountValue: 0,
      feeValue: 0,
      damageWaiverAppliableValue: 0,
      taxableValue: 0,
      baseValueExplanation: `This fee is a credit card fee of $${amount}.`,
    });
  };

  calculateGlobalFees = () => {
    (this.rentalObject.fee_rental_relationships_attributes || [])
      .filter((fee) => fee._destroy !== '1')
      .filter(
        (fee) =>
          fee.fee_target_type === 'global' && fee.fee_type !== 'credit_card_fee'
      )
      .forEach((globalFee, index) => {
        const baseValue =
          Math.round(
            (['percentage', 'percent'].includes(globalFee.value_type)
              ? this.findBillingLine('Inventory Total').finalValue *
                Number(globalFee.amount)
              : Number(globalFee.amount)) * 100
          ) / 100;
        const baseValueExplanation = ['percentage', 'percent'].includes(
          globalFee.value_type
        )
          ? `This fee amounts to ${
              Number(globalFee.amount) * 100
            }% of the total inventory value of ${
              this.findBillingLine('Inventory Total').finalValue
            }, totalling $${baseValue} after rounding to two decimals`
          : `This fee is a fixed fee of $${baseValue}.`;
        this.billingLines.push({
          tag: `5.1.${index + 1}`,
          kind: 'globalFeeLine',
          name: globalFee.name,
          aggregate: false,
          baseValue: baseValue,
          finalValue: baseValue,
          discountValue: 0,
          feeValue: 0,
          damageWaiverAppliableValue: 0,
          taxableValue: globalFee.tax_exempt ? 0 : baseValue,
          baseValueExplanation,
        });
      });

    const damageWaiverBaseValue = this.rentalObject.manual_damage_waiver
      ? Math.round(this.rentalObject.damage_waiver_fee_total * 100) / 100
      : this.automaticDamageWaiverValue();

    this.billingLines.push({
      tag: '5.2.1',
      kind: 'damageWaiverFee',
      name: 'Damage Waiver Fee',
      aggregate: false,
      baseValue: damageWaiverBaseValue,
      finalValue: damageWaiverBaseValue,
      discountValue: 0,
      feeValue: 0,
      damageWaiverAppliableValue: 0,
      taxableValue: this.rentalObject.damage_waiver_tax_exempt
        ? 0
        : damageWaiverBaseValue,
      baseValueExplanation: `This fee is a damage waiver fee $${damageWaiverBaseValue}. ${
        this.rentalObject.manual_damage_waiver
      }, ${this.automaticDamageWaiverValue()}.`,
    });

    (this.rentalObject.pick_list_damage_fees_attributes || []).forEach(
      (damageFee, index) => {
        const baseValue = Number(damageFee.amount);
        this.billingLines.push({
          tag: `5.3.1.${index + 1}`,
          kind: 'itemDamageFeeLine',
          name: `${damageFee.name} (${damageFee.feeable_attributes.name})`,
          aggregate: false,
          baseValue: baseValue,
          finalValue: baseValue,
          discountValue: 0,
          feeValue: 0,
          damageWaiverAppliableValue: 0,
          taxableValue: damageFee.tax_exempt ? 0 : baseValue,
          baseValueExplanation: `This fee was added as a damage fee related to the item "${damageFee.feeable_attributes.name}"`,
        });
      }
    );

    (this.rentalObject.pick_list_accessory_fees_attributes || []).forEach(
      (damageFee, index) => {
        const baseValue = Number(damageFee.amount);
        this.billingLines.push({
          tag: `5.3.2.${index + 1}`,
          kind: 'accessoryDamageFeeLine',
          name: `${damageFee.name} (${damageFee.feeable_attributes.name})`,
          aggregate: false,
          baseValue: baseValue,
          finalValue: baseValue,
          discountValue: 0,
          feeValue: 0,
          damageWaiverAppliableValue: 0,
          taxableValue: damageFee.tax_exempt ? 0 : baseValue,
          baseValueExplanation: `This fee was added as a damage fee related to the accessory "${damageFee.feeable_attributes.name}"`,
        });
      }
    );
  };

  calculateSalesTaxes = () => {
    this.billingLines.forEach((line) => {
      if (this.rentalObject.tax_exempt === true || line.taxableValue < 0)
        line.taxableValue = 0;
    });

    (this.rentalObject.sales_tax_rental_relationships_attributes || []).forEach(
      (strra, index) => {
        const tax = strra.sales_tax_attributes;

        if (tax._destroy == '1') return;
        else if (
          this.rentalObject.type === 'ParentRental' &&
          strra._destroy == '1'
        )
          return;

        let taxableSubtotal = this.findBillingLine('Subtotal').taxableValue;
        let notApplicableTaxableTotal = 0;

        if (tax.applies_to_rentables === false)
          notApplicableTaxableTotal +=
            this.findBillingLine('Rental Item Total').taxableValue +
            this.findBillingLine('Bundle Total').taxableValue;
        if (tax.applies_to_purchases === false)
          notApplicableTaxableTotal +=
            this.findBillingLine('Purchase Total').taxableValue;
        if (tax.applies_to_services === false)
          notApplicableTaxableTotal +=
            this.findBillingLine('Staff Total').taxableValue;
        taxableSubtotal -= notApplicableTaxableTotal;

        const baseValue =
          Math.round(taxableSubtotal * tax.tax_percent * 100) / 100;
        const notAppliableArray = [
          'Rentables (rental items and bundle totals)',
          'Purchases',
          'Services (staff total)',
        ].filter(
          (_, index) =>
            [
              tax.applies_to_rentables === false,
              tax.applies_to_purchases === false,
              tax.applies_to_services === false,
            ][index]
        );

        let baseValueExplanation;
        if (notAppliableArray.length === 0) {
          baseValueExplanation = `The sales tax of ${
            tax.tax_percent * 100
          }% was applied to the taxable subtotal of $${
            this.findBillingLine('Subtotal').taxableValue
          }, totalling $${baseValue} after rounding to two decimals.`;
        } else {
          baseValueExplanation = `This tax is not applicable to: ${notAppliableArray.join(
            ', '
          )}. Therefore, the taxable subtotal value of $${
            this.findBillingLine('Subtotal').taxableValue
          } was subtracted by $${notApplicableTaxableTotal}, totalling $${taxableSubtotal} taxable by this tax.
         This tax of ${
           tax.tax_percent * 100
         }% was applied to the taxable subtotal of $${taxableSubtotal}, totalling $${baseValue} after rounding to two decimals.`;
        }

        this.billingLines.push({
          tag: `7.1.${index + 1}`,
          kind: 'salesTaxesLine',
          name: `${tax.name} (${tax.tax_type}) - ${
            Math.round(tax.tax_percent * 100 * 100) / 100
          }%`,
          shortName: tax.name,
          aggregate: false,
          baseValue: baseValue,
          finalValue: baseValue,
          taxableValue: 0,
          discountValue: 0,
          feeValue: 0,
          damageWaiverAppliableValue: 0,
          baseValueExplanation,
          appliesToRentables: tax.applies_to_rentables,
          appliesToPurchases: tax.applies_to_purchases,
          appliesToServices: tax.applies_to_services,
          appliesTo: this.salesTaxesAppliesToDisplay(tax),
          createdFromOverride: tax.created_from_override,
          taxPercent: tax.tax_percent,
          taxType: tax.tax_type,
        });
      }
    );

    (
      this.billingLines.filter((bl) => bl.kind === 'rentalItemStandardLine') ||
      []
    ).forEach((rentalItemLine) => {
      const customTaxObject =
        rentalItemLine.rentalItemObject
          .rental_item_custom_tax_relationship_attributes;
      if (!customTaxObject || customTaxObject._destroy === '1') return;
      let baseValueExplanation;
      let baseValue;

      if (customTaxObject.amount_type == 'dollar') {
        baseValue =
          customTaxObject.amount * rentalItemLine.rentalItemObject.quantity;
        baseValueExplanation = `This tax is an additional tax for item ${rentalItemLine.rentalItemObject.name} of $${customTaxObject.amount} per item. The quantity of this item is ${rentalItemLine.rentalItemObject.quantity}. Therefore, the additional tax value is $${baseValue}.`;
      } else {
        baseValue =
          (customTaxObject.amount / 100) * rentalItemLine.finalValue;
        baseValueExplanation = `This tax is an additional tax for item ${rentalItemLine.rentalItemObject.name} of ${customTaxObject.amount}% of the taxable value. The taxable value of this item is $${rentalItemLine.finalValue}. Therefore, the additional tax value is $${baseValue}.`;
      }

      this.billingLines.push({
        tag: `7.2.${
          this.billingLines.filter((bl) => bl.kind === 'customTaxesLine')
            .length + 1
        }`,
        kind: 'customTaxesLine',
        name: `Additional tax - ${rentalItemLine.rentalItemObject.name}`,
        aggregate: false,
        baseValue: baseValue,
        finalValue: baseValue,
        taxableValue: 0,
        discountValue: 0,
        feeValue: 0,
        damageWaiverAppliableValue: 0,
        baseValueExplanation,
      });
    });
  };

  salesTaxesAppliesToDisplay = (tax) => {
    let appliesTo = [];
    if (tax.applies_to_rentables || tax.applies_to_rentables === null)
      appliesTo.push('Rent');
    if (tax.applies_to_purchases || tax.applies_to_purchases === null)
      appliesTo.push('Sale');
    if (tax.applies_to_services || tax.applies_to_services === null)
      appliesTo.push('Serv.');

    return appliesTo.length > 0 ? `(${appliesTo.join('/')})` : '';
  };

  automaticDamageWaiverValue = (inventoryTotal = undefined) => {
    const damageWaiverPercent = this.rentalObject.damage_waiver_percent;
    const damageWaiverFixedValue = this.rentalObject.damage_waiver_fixed_fee;

    if (inventoryTotal === undefined) {
      if (this.billingLines.length === 0) this.billingLinesForRental();
      inventoryTotal =
        this.findBillingLine('Inventory Total').damageWaiverAppliableValue;
    }

    return (
      (inventoryTotal || 0) * (Number(damageWaiverPercent || 0) / 100) +
      Number(damageWaiverFixedValue || 0)
    );
  };

  findBillingLine = (name) => {
    return this.billingLines.find((line) => line.name === name);
  };

  billableItemsOfType = (type) => {
    const parentKey = {
      RentalItem: 'product_id',
      RentalItemTemporary: '',
      RentalAddOn: 'add_on_id',
      RentalBundle: 'bundle_id',
      RentalStaff: 'staff_role_id',
    }[type];

    let items = this.rentalObject.rental_sections_attributes
      .filter((section) => section._destroy !== '1')
      .map((section) => section.section_memberships_attributes)
      .flat()
      .filter(
        (sectionMembership) =>
          sectionMembership.rental_inventory_type === type &&
          sectionMembership.parent_connection_type !== 'duplicate' &&
          sectionMembership._destroy != 1
      )
      .map((sectionMembership) => ({
        ...sectionMembership.rental_inventory_attributes,
        entity_type: type,
        entity_parent_id:
          sectionMembership.rental_inventory_attributes[parentKey],
      }))
      .flat()
      .filter(
        (item) => item && item._destroy != 1 && !item.remove_from_pricing
      );

    if (items && items.length > 0) return items;

    items =
      this.rentalObject[
        {
          RentalItem: 'rental_item_standards_attributes',
          RentalItemTemporary: 'rental_item_temporaries_attributes',
          RentalAddOn: 'rental_add_ons_attributes',
          RentalBundle: 'rental_bundles_attributes',
          RentalStaff: 'rental_staffs_attributes',
        }[type]
      ];

    if (items && items.length > 0) return items;

    return [];
  };

  basePriceUnitForTimeBasedPricing = () => {
    return Object.keys(PricingService.TIME_UNIT_CONVERSION_FACTORS)
      .filter((timeUnit) => {
        return (
          PricingService.TIME_UNIT_CONVERSION_FACTORS[timeUnit] <=
          this.durationInHours()
        );
      })
      .pop();
  };

  durationInHours = (entity = undefined) => {
    let startDateTime;
    let endDateTime;

    if (entity && entity.start_date_time && entity.end_date_time) {
      startDateTime = new Date(entity.start_date_time);
      endDateTime = new Date(entity.end_date_time);
    } else {
      startDateTime = new Date(
        this.rentalObject.schedule_attributes.event_start_date_time
      );
      endDateTime = new Date(
        this.rentalObject.schedule_attributes.event_end_date_time
      );
    }

    return Math.ceil(
      (endDateTime.getTime() - startDateTime.getTime()) / 3600000
    );
  };

  durationInHoursOfStaffSchedule = (staffObject) => {
    const staffSchedule = staffObject.staff_schedule_attributes;
    let startDateTime = new Date(staffSchedule.in_time);
    let endDateTime = new Date(staffSchedule.out_time);

    if (
      staffObject.rental_staff_recurrence_attributes &&
      staffObject.rental_staff_recurrence_attributes.is_recurring
    ) {
      // If a rental staff is reocurring, we need to calculate the duration in hours of one single day of the recurrence, and then multiply by the number of service tickets
      // To calculate the hours in one day, both the in time and end time get normalized to todays date to simulate being on the same day
      // Then, the baseValue gets multiplied by the number of service tickets on the calculateStaffPricing method
      const todayDate = new Date();
      startDateTime = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth(),
        todayDate.getDate(),
        startDateTime.getHours(),
        startDateTime.getMinutes()
      );
      endDateTime = new Date(
        todayDate.getFullYear(),
        todayDate.getMonth(),
        todayDate.getDate(),
        endDateTime.getHours(),
        endDateTime.getMinutes()
      );
    }

    return Math.ceil(
      (endDateTime.getTime() - startDateTime.getTime()) / 3600000
    );
  };

  durationInGivenUnit = (durationInHours, unit) => {
    if (durationInHours === 0) return 0;

    return Math.max(
      1,
      durationInHours / PricingService.TIME_UNIT_CONVERSION_FACTORS[unit]
    );
  };

  possibleSecondaryUnits = (primaryUnit) => {
    // Combined-rate pricing uses a secondary rate only if the secondary rate is at most 2 units below the primary unit rate
    // For example, if primary unit rate is weekly, secondary rate can be daily or half-day, but not hourly
    const primaryUnitIndex =
      PricingService.TIME_BASED_UNITS.indexOf(primaryUnit);
    return PricingService.TIME_BASED_UNITS.slice(
      Math.max(0, primaryUnitIndex - 2),
      primaryUnitIndex
    ).reverse();
  };

  availablePriceNames = (rentalItemObject) => {
    return PricingService.TIME_BASED_UNITS.filter(
      (unit) =>
        rentalItemObject[`${unit}_price`] &&
        rentalItemObject[`${unit}_price`] > 0
    ).map((unit) => PricingService.TIME_UNIT_NAMES[unit]);
  };

  accountingSort = (arr) => {
    return arr.sort((a, b) =>
      a.tag
        .replace(/\d+/g, (n) => +n + 100000)
        .localeCompare(b.tag.replace(/\d+/g, (n) => +n + 100000))
    );
  };

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return '';
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  formatPrice(price) {
    return '$' + Number(price).toFixed(2);
  }
}
