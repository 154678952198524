import ActionCable from "actioncable";
import { getAuthToken } from "../api";

let consumer: ActionCable.Cable | null = null;

export const getActionCableConsumer = (extraParams: Record<string, string> = {}): ActionCable.Cable => {
  if (!consumer) {
    const authToken = getAuthToken();
    
    const params = {
      "access-token": authToken["access-token"],
      client: authToken.client,
      uid: authToken.uid,
      resource_type: "employee",
      ...extraParams,
    };

    const queryString = new URLSearchParams(params).toString();
    const URL = `${process.env.REACT_APP_ACTION_CABLE}/cable?${queryString}`;

    consumer = ActionCable.createConsumer(URL);
  }
  return consumer;
};
