import React from 'react';
import {
  Team,
  Company,
  Items,
  Export,
  Revenue,
  PriceTag,
  Percentage,
  Profit,
  Receipt,
  Smiley,
  Document,
  Roles,
  QuickbooksCircle,
} from 'Components/utils/SvgIcons';

export const divideIntoThreeSubarrays = (array) => {
  let result = [];
  for (let i = 3; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
};

const reportLinks = (
  multilocationReportsEnabled = false,
  generalLedgerEnabled = false,
  robustAndCustomReports = false,
  quickbooksIntegration = false,
  npsCustomerSurveys = false,
) => {
  return [
    {
      icon: <Export />,
      to: robustAndCustomReports ? '/admin/reports/export' : '/upgrade/customExport',
      id: 'custom_export',
      name: 'Custom Export',
      noMobile: true,
    },
    {
      icon: <QuickbooksCircle />,
      to: quickbooksIntegration ? '/admin/reports/quickbooks' : '/upgrade/quickbooks',
      name: 'QuickBooks',
    },
    {
      icon: <Revenue />,
      to: '/admin/reports/revenue',
      name: 'Revenue',
    },
    {
      icon: <PriceTag />,
      to: '/admin/reports/payments',
      name: 'Payments',
    },
    {
      icon: <Percentage />,
      to: '/admin/reports/salesTax/newSalesTaxReport',
      name: 'Sales Tax',
    },
    {
      icon: <Items />,
      to: '/admin/reports/popularItems',
      name: 'Popular Items',
    },
    {
      icon: <Profit />,
      to: '/admin/reports/profit',
      id: 'profitability',
      name: 'Profitability',
    },
    {
      icon: <Team />,
      to: '/admin/reports/bestCustomers',
      name: 'Best Customers',
    },
    {
      icon: <Smiley />,
      to: npsCustomerSurveys ? '/admin/reports/nps' : '/upgrade/nps',
      id: 'customer_survey',
      name: 'Customer Survey',
    },
    {
      icon: <Document />,
      to: '/admin/reports/documents',
      name: 'Documents',
    },
    {
      icon: <Receipt />,
      to: '/admin/reports/billing',
      id: 'billing_status',
      name: 'Billing Status',
    },
    {
      icon: <Items />,
      to: '/admin/reports/inventory',
      name: 'Inventory',
    },
    {
      icon: <Roles />,
      to: '/admin/reports/staffing',
      name: 'Staffing',
    },
    {
      icon: <Receipt />,
      to: '/admin/reports/total_order_volume',
      name: 'Total Order Volume',
    },
    {
      icon: <Company />,
      to: multilocationReportsEnabled ? '/admin/reports/multilocation' : '/upgrade/multilocation',
      name: 'Multilocation Reports',
    },
    {
      icon: <Profit />,
      to: generalLedgerEnabled ? '/admin/reports/generalLedger' : '/upgrade/generalLedger',
      name: 'General Ledger',
    },
  ].filter(Boolean);
};

export const reportLinkColumns = (
  multilocationReportsEnabled = false,
  generalLedgerEnabled = false,
  robustAndCustomReports = false,
  quickbooksIntegration = false,
  npsCustomerSurveys = false,
) => {
  return divideIntoThreeSubarrays(
    reportLinks(
      multilocationReportsEnabled,
      generalLedgerEnabled,
      robustAndCustomReports,
      quickbooksIntegration,
      npsCustomerSurveys,
    )
  );
};
