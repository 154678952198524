import { OPEN_VERSION_MODAL, CLOSE_VERSION_MODAL } from "Constants/redux";

export const openVersionModal = label => {
  return {
    type: OPEN_VERSION_MODAL
  };
};
export const closeVersionModal = () => {
  return {
    type: CLOSE_VERSION_MODAL
  };
};
export const refreshVersionModal = () => {
  window.location.reload();
  return {
    type: CLOSE_VERSION_MODAL
  };
};
