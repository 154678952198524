export const isDefaultVersionGreater = (defaultVersion, currentVersion) => {
  const versionRegex = /^v\d+\.\d+\.\d+(-\d+)?$/;
  const dateRegex = /^\d{8}-\d+$/;

  let isVersion = versionRegex.test(defaultVersion) && versionRegex.test(currentVersion);
  let isDate = dateRegex.test(defaultVersion) && dateRegex.test(currentVersion);

  if (isVersion) {
    const currentV = currentVersion.replace(/^v/, '').split('-')[0];
    const defaultV = defaultVersion.replace(/^v/, '').split('-')[0];

    const [majorCurrent, minorCurrent, patchCurrent] = currentV.split('.').map(Number);
    const [majorDefault, minorDefault, patchDefault] = defaultV.split('.').map(Number);
    const buildCurrent = currentVersion.split('-')[1] || 0;
    const buildDefault = defaultVersion.split('-')[1] || 0;

    if (majorDefault > majorCurrent) return true;
    if (majorDefault < majorCurrent) return false;
    if (minorDefault > minorCurrent) return true;
    if (minorDefault < minorCurrent) return false;
    if (patchDefault > patchCurrent) return true;
    if (patchDefault < patchCurrent) return false;
    return buildDefault > buildCurrent;
  } else if (isDate) {
    const currentVMajor = currentVersion.split('-')[0];
    const defaultVMajor = defaultVersion.split('-')[0];
    const currentVMinor = currentVersion.split('-')[1];
    const defaultVMinor = defaultVersion.split('-')[1];

    return defaultVMajor > currentVMajor || (defaultVMajor === currentVMajor && defaultVMinor > currentVMinor);
  }
  return false;
}

