import axios from 'axios';
import {
  getAuthToken,
  saveAuthToken,
  getAdminAuthToken,
  saveAdminAuthToken,
} from 'Api';
import * as actions from 'Actions';
import { setErrors } from '../actions';
import converter from 'json-style-converter/es5';
import { isObject } from 'HelperFunctions/types';
import { extractContentFromHtml } from 'HelperFunctions/html';
import { isDefaultVersionGreater } from 'HelperFunctions/version';
import { notifyDatadogAndAirbrake, store } from '../components/Root';

const Service = (
  headerFunc = () => {},
  saveAuthFunc = () => {},
  useInjection = false
) => {
  class WrappedService {
    service = () => {
      const version = `X-Version-${process.env.REACT_APP_VERSION}`;
      let service = axios.create({
        headers: headerFunc(),
      });
      service.interceptors.response.use(this.handleSuccess, this.handleError);
      service.defaults.headers.common[version] = 'always';
      axios.defaults.headers.common[version] = 'always';
      return service;
    };

    cleanResponseContent = (response) => {
      if (!!response.data && typeof response.data === 'string') {
        return extractContentFromHtml(response.data);
      }
      return undefined;
    };

    dispatchNonJsonErrorsAndNotify = (response) => {
      store.dispatch(setErrors('Unusable response data'));
      notifyDatadogAndAirbrake(
        'Service response error - Non JSON',
        'Service',
        this.cleanResponseContent(response)
      );
    };

    dispatchEmptyResponseErrorsAndNotify = () => {
      store.dispatch(
        setErrors(
          'A null response was passed, this usually means a CORS or other browser based error that stopped the request before sending'
        )
      );
      notifyDatadogAndAirbrake(
        'Service request error - A null response was passed, this usually means a CORS or other browser based error that stopped the request before sending',
        'Service'
      );
    };

    //new global handler to take care of checking content-type first BEFORE checking HTTP status
    handleResponse = (response) => {
      if (!response) {
        this.dispatchEmptyResponseErrorsAndNotify();
        return response;
      }
      const contentType = response.headers['content-type'];
      const responseStatus = parseInt(response.status);

      if (contentType && contentType.startsWith('application/json')) {
        if (responseStatus === 401) {
          //store.dispatch(signOutUser());
          store.dispatch(
            setErrors('Your session has expired. Please log in again.')
          );
        } else if (responseStatus === 404) {
          store.dispatch(
            setErrors('The resource you requested is not found - 404.')
          );
          //history.push('/notFound');
        }
        // TODO add other types of errors?
        return response;
      } else {
        //now we know it was not json let's check HTTP codes
        //first check if it is a known no content and retun an empty JSON object
        if (responseStatus === 204) {
          return response;
        } else if (Math.floor(responseStatus / 100) !== 2) {
          this.dispatchNonJsonErrorsAndNotify(response);
        } else {
          //the request is okay, maybe it is a suitable content type that can still be parsed
          if (
            contentType &&
            (contentType.startsWith('text/') ||
              contentType === 'application/javascript' ||
              contentType === 'application/ld+json')
          ) {
            if (!isObject(response.data)) {
              try {
                return {
                  ...response,
                  data: JSON.parse(response.data),
                };
              } catch (e) {
                this.dispatchNonJsonErrorsAndNotify(response);
              }
            }
          } else {
            this.dispatchNonJsonErrorsAndNotify(response);
          }
        }
      }
      return response;
    };

    // Check for updated permissions and update the store
    refreshBusinessPermissions = (response) => {
      if (!response?.data?.updatedBusinessPermissions) return;

      store.dispatch(actions.openLoadingSpinner());

      saveAuthToken(response.headers);
      store.dispatch(
        actions.updateBusinessPermissions(
          converter.snakeToCamelCase(response.data.updatedBusinessPermissions)
        )
      );

      store.dispatch(actions.closeLoadingSpinner());
    };

    handleSuccess = (response) => {
      const newResponse = this.handleResponse(response);

      this.refreshBusinessPermissions(newResponse);

      this.handleVersioning(newResponse);
      if (useInjection) {
        saveAuthFunc(newResponse.headers);
      }
      return newResponse;
    };

    handleError = (error) => {
      const newResponse = this.handleResponse(error.response);
      console.error(error, 'web request error');
      this.handleVersioning(newResponse);
      return Promise.reject(error);
    };

    handleVersioning = (response) => {
      const { open, last_triggered } = store.getState().version;

      if (process.env.REACT_APP_VERSION_CHECK_ENABLED === 'false' || open)
        return;
      if (
        last_triggered &&
        new Date().getTime() - last_triggered <
          parseInt(process.env.REACT_APP_VERSION_CHECK_MODAL_INTERVAL)
      )
        return;
      if (response) {
        const headers = response.headers;
        if (
          headers['version'] &&
          isDefaultVersionGreater(
            headers['version'],
            process.env.REACT_APP_VERSION
          )
        ) {
          store.dispatch(actions.openVersionModal());
        }
      }
    };

    redirectTo = (document, path) => {
      document.location = path;
    };

    get(path, params) {
      return this.service().get(path, {
        params,
      });
    }

    delete(path, options) {
      return this.service().delete(path, options);
    }

    patch(path, payload, options = {}) {
      return this.service().request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        cancelToken: options.cancelToken,
      });
    }

    put(path, payload, options = {}) {
      return this.service().request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        params: options.params,
        cancelToken: options.cancelToken,
      });
    }

    post(path, payload, options = {}) {
      return this.service().request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
        cancelToken: options.cancelToken,
      });
    }
    all(promises) {
      return Promise.all(promises);
    }
    spread(callback) {
      return (arr) => {
        return callback.apply(null, arr);
      };
    }
  }
  return WrappedService;
};

const ServiceClass = Service(getAuthToken, saveAuthToken, true);
export default new ServiceClass();

const AdminServiceClass = Service(getAdminAuthToken, saveAdminAuthToken, true);
export const adminService = new AdminServiceClass();

const StandardServiceClass = Service();
export const standardService = new StandardServiceClass();
