import React from 'react';
import { Spinner } from 'Utils/SvgIcons';

const OverlaySpinner = ({ loading = true, label, className, ...props }) => {
  if (!loading) {
    return <div />;
  }

  return (
    <div className={`overlay modalSpinner ${className}`} {...props}>
      <div className='spinner'>
        <Spinner />
        <p>{label}</p>
      </div>
    </div>
  );
};

export default OverlaySpinner;
