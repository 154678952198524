import React from 'react';
import { connect } from 'react-redux';
import { fetchRentalIfNeeded } from 'Actions';
import classnames from 'classnames';
import { Company, Items } from 'Utils/SvgIcons';
import { FormattedDate, FormattedTime } from 'react-intl';
import { deliveryTypes } from 'Constants/displayConstants';
import { parseQuery } from 'HelperFunctions/QueryString';
import { formatPhoneNumber } from 'HelperFunctions/general';
import Imgix from 'react-imgix';
import QRCode from 'react-qr-code';
import { concat, groupBy, uniq } from 'lodash';
import { dateToFormattedUTCString } from 'HelperFunctions/DateHelpers';
import {
  getSignatureFromRental,
  mergeRentalPickListSignatures,
} from 'HelperFunctions/RentalSignatures';
import originalTerminologies from '../../helper_functions/originalTerminologies';

class PrintPickList extends React.Component {
  componentDidMount() {
    const { fetchRentalIfNeeded } = this.props;
    fetchRentalIfNeeded(this.props.match.params.id);
  }

  hasDimensions(product) {
    if (product) {
      return (
        product.warehouseLocation ||
        product.weight ||
        product.lengthFt ||
        product.length ||
        product.widthFt ||
        product.width ||
        product.heightFt ||
        product.height
      );
    } else {
      return false;
    }
  }

  renderDimensions(product) {
    return (
      <div>
        {product.warehouseLocation && <div>{product.warehouseLocation}</div>}
        {product.weight && (
          <div>
            {product.weight}
            lbs
          </div>
        )}
        {(product.lengthFt ||
          product.length ||
          product.widthFt ||
          product.width ||
          product.heightFt ||
          product.height) && (
          <div>
            {(product.lengthFt || product.length) && (
              <span>
                {'L:'}
                {product.lengthFt ? `${product.lengthFt}' ` : ''}
                {product.lengthFt && product.length && ' '}
                {product.length ? `${product.length}"` : ''}
              </span>
            )}
            {(product.widthFt || product.width) && (
              <span>
                {(product.lengthFt || product.length) && '   '}
                {'W:'}
                {product.widthFt ? `${product.widthFt}' ` : ''}
                {product.widthFt && product.width && ' '}
                {product.width ? `${product.width}"` : ''}
              </span>
            )}
            {(product.heightFt || product.height) && (
              <span>
                {(product.lengthFt ||
                  product.length ||
                  product.widthFt ||
                  product.width) &&
                  ' x '}
                {'H:'}
                {product.heightFt ? `${product.heightFt}' ` : ''}
                {product.heightFt && product.height && ' '}
                {product.height ? `${product.height}"` : ''}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }

  renderCustomer = (customerRentalRelationship, index) => {
    const { rental } = this.props;
    const customerRelationship = customerRentalRelationship.client;
    let clientInfo = customerRelationship.finalInfo
      ? customerRelationship.finalInfo
      : customerRelationship;

    return (
      <div key={index}>
        {customerRentalRelationship.isPrimaryContact && (
          <div className='caption'>Primary</div>
        )}
        <h2>{clientInfo.name}</h2>
        <div>{clientInfo.email}</div>
        {index === 0 &&
          rental &&
          !clientInfo.office &&
          !clientInfo.cell &&
          !clientInfo.fax &&
          rental.customerContactPhone && (
            <div>{formatPhoneNumber(rental.customerContactPhone)}</div>
          )}
        {clientInfo && clientInfo.office && (
          <div>{formatPhoneNumber(clientInfo.office)}</div>
        )}
        {clientInfo && clientInfo.cell && (
          <div>{formatPhoneNumber(clientInfo.cell)}</div>
        )}
        {clientInfo && clientInfo.fax && (
          <div>{formatPhoneNumber(clientInfo.fax)}</div>
        )}
        {(clientInfo.streetAddress1 ||
          clientInfo.streetAddress2 ||
          clientInfo.postalCode ||
          clientInfo.locale ||
          clientInfo.city ||
          clientInfo.country) && (
          <div>
            <div>{clientInfo.streetAddress1}</div>
            <div>{clientInfo.streetAddress2}</div>
            <div>
              {clientInfo.city}, {clientInfo.locale} {clientInfo.postalCode},{' '}
              {clientInfo.country}
            </div>
          </div>
        )}
      </div>
    );
  };

  groupLikeItems = (items) => {
    const idField = 'productId';
    const inventoryType = 'product';
    let itemsCopy = [...items];
    return itemsCopy.reduce((agg, curr) => {
      let dupIndex = agg.findIndex(
        (item) =>
          item[idField] === curr[idField] &&
          item.itemNotes?.length === 0 &&
          curr.itemNotes?.length === 0
      );
      let dup = agg[dupIndex];
      if (dupIndex > -1 && curr.type !== 'RentalItemTemporary') {
        agg[dupIndex] = {
          isGrouped: true,
          [idField]: curr[idField],
          count: dup.count ? dup.count + 1 : 2,
          name: curr[inventoryType].name,
          quantity: dup.quantity + curr.quantity,
          position: dup.position,
          ...(curr.selectedPrice && {
            subTotal: dup.subTotal
              ? parseFloat(dup.subTotal) + parseFloat(curr.selectedPrice)
              : parseFloat(dup.selectedPrice) + parseFloat(curr.selectedPrice),
          }),
          items: dup.items ? [...dup.items, curr] : [dup, curr],
          product: dup.product,
        };
      } else {
        agg.push(curr);
      }
      return agg;
    }, []);
  };

  groupLikeAccessories = (items) => {
    const idField = 'accessoryId';
    const inventoryType = 'accessory';
    let itemsCopy = [...items];
    return itemsCopy.reduce((agg, curr) => {
      let dupIndex = agg.findIndex(
        (item) =>
          item[idField] === curr[idField] &&
          item.itemNotes?.length === 0 &&
          curr.itemNotes?.length === 0
      );
      let dup = agg[dupIndex];
      if (dupIndex > -1 && curr.type !== 'RentalItemTemporary') {
        agg[dupIndex] = {
          isGrouped: true,
          [idField]: curr[idField],
          count: dup.count ? dup.count + 1 : 2,
          name: curr[inventoryType].name,
          quantity: dup.quantity + curr.quantity,
          position: dup.position,
          ...(curr.selectedPrice && {
            subTotal: dup.subTotal
              ? parseFloat(dup.subTotal) + parseFloat(curr.selectedPrice)
              : parseFloat(dup.selectedPrice) + parseFloat(curr.selectedPrice),
          }),
          items: dup.items ? [...dup.items, curr] : [dup, curr],
          accessory: dup.accessory,
        };
      } else {
        agg.push(curr);
      }
      return agg;
    }, []);
  };

  groupLikeAddons = (items) => {
    const idField = 'addOnId';
    const inventoryType = 'addOn';
    let itemsCopy = [...items];
    return itemsCopy.reduce((agg, curr) => {
      let dupIndex = agg.findIndex(
        (item) =>
          item[idField] === curr[idField] &&
          item.itemNotes?.length === 0 &&
          curr.itemNotes?.length === 0
      );
      let dup = agg[dupIndex];
      if (dupIndex > -1 && curr.type !== 'RentalItemTemporary') {
        agg[dupIndex] = {
          isGrouped: true,
          [idField]: curr[idField],
          count: dup.count ? dup.count + 1 : 2,
          name: curr[inventoryType].name,
          quantity: dup.quantity + curr.quantity,
          position: dup.position,
          ...(curr.selectedPrice && {
            subTotal: dup.subTotal
              ? parseFloat(dup.subTotal) + parseFloat(curr.selectedPrice)
              : parseFloat(dup.selectedPrice) + parseFloat(curr.selectedPrice),
          }),
          items: dup.items ? [...dup.items, curr] : [dup, curr],
          addOn: dup.addOn,
        };
      } else {
        agg.push(curr);
      }
      return agg;
    }, []);
  };

  groupLikeBundles = (items) => {
    const idField = 'bundleId';
    const inventoryType = 'bundle';
    let itemsCopy = [...items];
    return itemsCopy.reduce((agg, curr) => {
      let dupIndex = agg.findIndex(
        (item) =>
          item[idField] === curr[idField] &&
          item.itemNotes?.length === 0 &&
          curr.itemNotes?.length === 0
      );
      let dup = agg[dupIndex];
      if (
        dupIndex > -1 &&
        curr.type !== 'RentalItemTemporary' &&
        curr.rentalSectionId === dup.rentalSectionId
      ) {
        agg[dupIndex] = {
          isGrouped: true,
          [idField]: curr[idField],
          count: dup.count ? dup.count + 1 : 2,
          name: curr[inventoryType].name,
          quantity: dup.quantity + curr.quantity,
          position: dup.position,
          ...(curr.selectedPrice && {
            subTotal: dup.subTotal
              ? parseFloat(dup.subTotal) + parseFloat(curr.selectedPrice)
              : parseFloat(dup.selectedPrice) + parseFloat(curr.selectedPrice),
          }),
          items: dup.items ? [...dup.items, curr] : [dup, curr],
          rentalItems: dup.rentalItems,
          rentalAccessories: dup.rentalAccessories,
          rentalAddOns: dup.rentalAddOns,
        };
      } else {
        agg.push(curr);
      }
      return agg;
    }, []);
  };

  renderSubRentalItems(subRentals) {
    return (
      <div>
        {subRentals.map((subrental, index) => (
          <div
            key={index}
            className='subRentalContainer'
            style={{ backgroundColor: subrental.locationColor || '#278ADA' }}
          >
            <p>
              {subrental.supplierLocationCode ||
                subrental.supplierName ||
                'Manual Subrental'}
              : {subrental.quantity}
            </p>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { location, itemNotesView, user } = this.props;
    let { rental } = this.props;
    const {
      companyRentalRelationship,
      customerRentalRelationships,
      company,
      salesperson,
      schedule,
      delivery,
      pickup,
      items,
      accessories,
      addons,
      bundles,
      staffs,
      notes,
    } = rental;

    const groupedItems = this.groupLikeItems(items);
    const groupedAccessories = this.groupLikeAccessories(accessories);
    const groupedAddons = this.groupLikeAddons(addons);
    const groupedBundles = this.groupLikeBundles(bundles);

    let { detailView, sectionType } = parseQuery(window.location.search);

    const tileView = location.showInventoryPhotoInPdf;

    detailView = detailView === 'true';
    const customerPickUp = delivery.delivery_type === 'customer_pick_up';

    const getSortedKeys = (
      itemsCollection,
      accessoriesCollection,
      AddOnsCollection,
      bundlesCollection
    ) => {
      return uniq(
        concat(
          Object.keys(itemsCollection),
          Object.keys(accessoriesCollection),
          Object.keys(AddOnsCollection),
          Object.keys(bundlesCollection)
        )
      ).sort();
    };

    const groupedByDepartments = (elements, key) => {
      return groupBy(elements, (e) => {
        return !!e[key]?.departmentNames
          ? e[key].departmentNames
          : 'No Department Assigned';
      });
    };

    const departmentItems = groupedByDepartments(groupedItems, 'product');
    const departmentAccessories = groupedByDepartments(
      groupedAccessories,
      'accessory'
    );
    const departmentAddOns = groupedByDepartments(groupedAddons, 'addOn');
    const departmentBundles = groupedByDepartments(groupedBundles, 'bundle');
    const departmentsKeys = getSortedKeys(
      departmentItems,
      departmentAccessories,
      departmentAddOns,
      departmentBundles
    );
    let groupedDepartments = {};

    const departmentElements = [
      departmentItems,
      departmentAccessories,
      departmentAddOns,
      departmentBundles,
    ];
    departmentsKeys.forEach((departmentKey) => {
      groupedDepartments[departmentKey] = [];
      departmentElements.forEach((elements) => {
        if (elements[departmentKey]) {
          groupedDepartments[departmentKey].push(...elements[departmentKey]);
        }
      });
    });

    const groupedByCategories = (elements, key) => {
      return groupBy(elements, (e) => {
        return !!e[key]?.sfCategories?.length
          ? uniq(e[key].sfCategories.map((category) => category.name)).join(
              ', '
            )
          : 'No Category Assigned';
      });
    };

    const categoryItems = groupedByCategories(groupedItems, 'product');
    const categoryAccessories = groupedByCategories(
      groupedAccessories,
      'accessory'
    );
    const categoryAddOns = groupedByCategories(groupedAddons, 'addOn');
    const categoryBundles = groupedByCategories(groupedBundles, 'bundle');
    const categoryKeys = getSortedKeys(
      categoryItems,
      categoryAccessories,
      categoryAddOns,
      categoryBundles
    );
    let groupedCategories = {};

    const categoryElements = [
      categoryItems,
      categoryAccessories,
      categoryAddOns,
      categoryBundles,
    ];
    categoryKeys.forEach((categoryKey) => {
      groupedCategories[categoryKey] = [];
      categoryElements.forEach((elements) => {
        if (elements[categoryKey]) {
          groupedCategories[categoryKey].push(...elements[categoryKey]);
        }
      });
    });

    const customerSignatureCheckIn = getSignatureFromRental(
      rental,
      'customerCheckIn'
    );
    const customerSignatureCheckOut = getSignatureFromRental(
      rental,
      'customerCheckOut'
    );
    rental = mergeRentalPickListSignatures(
      rental,
      customerSignatureCheckIn,
      customerSignatureCheckOut
    );

    return (
      <div className='printWrapper'>
        <div className='content withoutGrid'>
          <div className='header'>
            <div className='logo'>
              <figure>
                {location.picture === null ? (
                  <Company />
                ) : (
                  <Imgix
                    src={location.picture.imgixUrl}
                    alt={location.name}
                    width={510}
                    height={288}
                  />
                )}
              </figure>
              <div className='businessPrint'>
                <h2>{location.name}</h2>
                {location.physicalAddress.streetAddress1}
                {location.physicalAddress.streetAddress2 &&
                  `#${location.physicalAddress.streetAddress2}`}
                <br />
                {location.physicalAddress.city},{' '}
                {location.physicalAddress.locale}{' '}
                {location.physicalAddress.postalCode},{' '}
                {location.physicalAddress.country}
              </div>
            </div>
            <div className='title'>
              <div className='orderInfo'>
                <h4
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    height: '100%',
                  }}
                >
                  Order Info
                </h4>
              </div>
              <div className='token promtArea'>
                <QRCode
                  value={`${window.location.origin}/orders/rentals/${rental.token}/picklist`}
                  size={60}
                />
                <h4 className='smptxt'>#{rental.token}</h4>
                {rental.customId && (
                  <div>
                    {originalTerminologies().customIdSingular}:{' '}
                    {rental.customId}
                  </div>
                )}
              </div>
              <h1>{rental.name}</h1>
              <div className='dates promtArea'>
                <label>Order Start</label>
                <FormattedDate
                  weekday='short'
                  month='short'
                  day='numeric'
                  year='numeric'
                  value={schedule.eventStartDate}
                />{' '}
                <FormattedTime
                  value={schedule.eventStartTime}
                  timeZone={schedule.timeZone}
                  timeZoneName='short'
                />
                <label>Order End</label>
                <FormattedDate
                  weekday='short'
                  month='short'
                  day='numeric'
                  year='numeric'
                  value={schedule.eventEndDate}
                />{' '}
                <FormattedTime
                  value={schedule.eventEndTime}
                  timeZone={schedule.timeZone}
                  timeZoneName='short'
                />
              </div>
            </div>
          </div>

          <div className='details'>
            <section className='deliveryPrint'>
              <h4>Delivery Info</h4>
              <table className='grid'>
                <tbody>
                  <tr className='promtArea'>
                    <td>Type</td>
                    <td className='smptxt'>
                      {deliveryTypes[delivery.delivery_type]}
                    </td>
                  </tr>
                  {!customerPickUp && (
                    <tr className='top'>
                      <td>
                        Delivery
                        <br />
                        Address
                      </td>
                      <td>
                        <div>
                          {delivery.delivery_address_location_name && (
                            <div>{delivery.delivery_address_location_name}</div>
                          )}
                          <div>
                            {delivery.delivery_address_street_address_1}
                          </div>
                          {delivery.delivery_address_street_address_2 && (
                            <div>
                              # {delivery.delivery_address_street_address_2}
                            </div>
                          )}
                          <div>
                            {delivery.delivery_address_city},{' '}
                            {delivery.delivery_address_locale}{' '}
                            {delivery.delivery_address_postal_code},{' '}
                            {delivery.delivery_address_country}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  {!customerPickUp &&
                    delivery.delivery_type !== 'ship' &&
                    pickup.pickup_same_as_delivery === false && (
                      <tr className='top'>
                        <td>
                          Pick Up
                          <br />
                          Address
                        </td>
                        <td>
                          <div>
                            {pickup.pickup_address_location_name && (
                              <div>{pickup.pickup_address_location_name}</div>
                            )}
                            <div>{pickup.pickup_address_street_address_1}</div>
                            {pickup.pickup_address_street_address_2 && (
                              <div>
                                # {pickup.pickup_address_street_address_2}
                              </div>
                            )}
                            <div>
                              {pickup.pickup_address_city},{' '}
                              {pickup.pickup_address_locale}{' '}
                              {pickup.pickup_address_postal_code},{' '}
                              {pickup.pickup_address_country}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </section>

            <section className='checkInPrint promtArea'>
              <h4>
                {customerPickUp ? 'Pick Up / Drop off' : 'Drop off / Pick Up'}
              </h4>
              <table className='grid'>
                <tbody>
                  <tr className='top'>
                    <td>{customerPickUp ? 'Pick Up' : 'Drop off'}</td>
                    <td>
                      {rental.schedule.eventStartSetting !== 'no_preference' ? (
                        <div>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.startDate}
                          />{' '}
                          <FormattedTime
                            value={schedule.startTime}
                            timeZone={schedule.timeZone}
                            timeZoneName='short'
                          />
                        </div>
                      ) : (
                        <div>No Preference</div>
                      )}
                      <div className='caption'>Through</div>
                      {rental.schedule.eventStartSetting !== 'no_preference' ? (
                        <div>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.startWindowFinish}
                          />{' '}
                          <FormattedTime
                            value={schedule.startWindowFinish}
                            timeZone={schedule.timeZone}
                            timeZoneName='short'
                          />
                        </div>
                      ) : (
                        <div>No Preference</div>
                      )}
                    </td>
                  </tr>
                  <tr className='top'>
                    <td>{customerPickUp ? 'Drop off' : 'Pick Up'}</td>
                    <td>
                      {rental.schedule.eventEndSetting !== 'no_preference' ? (
                        <div>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.endWindowBeginning}
                          />{' '}
                          <FormattedTime
                            value={schedule.endWindowBeginning}
                            timeZone={schedule.timeZone}
                            timeZoneName='short'
                          />
                        </div>
                      ) : (
                        <div>No Preference</div>
                      )}
                      <div className='caption'>Through</div>
                      {rental.schedule.eventEndSetting !== 'no_preference' ? (
                        <div>
                          <FormattedDate
                            weekday='short'
                            month='short'
                            day='numeric'
                            year='numeric'
                            value={schedule.endDate}
                          />{' '}
                          <FormattedTime
                            value={schedule.endTime}
                            timeZone={schedule.timeZone}
                            timeZoneName='short'
                          />
                        </div>
                      ) : (
                        <div>No Preference</div>
                      )}
                    </td>
                  </tr>
                  {schedule.shelfEnabled && (
                    <tr>
                      <td>Off Shelf</td>
                      <td>
                        <FormattedDate
                          weekday='short'
                          month='short'
                          day='numeric'
                          year='numeric'
                          value={schedule.offShelfAt}
                        />{' '}
                        <FormattedTime
                          value={schedule.offShelfAt}
                          timeZone={schedule.timeZone}
                          timeZoneName='short'
                        />
                      </td>
                    </tr>
                  )}
                  {schedule.shelfEnabled && (
                    <tr>
                      <td>On Shelf</td>
                      <td>
                        <FormattedDate
                          weekday='short'
                          month='short'
                          day='numeric'
                          year='numeric'
                          value={schedule.onShelfAt}
                        />{' '}
                        <FormattedTime
                          value={schedule.onShelfAt}
                          timeZone={schedule.timeZone}
                          timeZoneName='short'
                        />
                      </td>
                    </tr>
                  )}
                  {rental.hasSetByTime && (
                    <tr>
                      <td className='sm withoutBorder'>Set By Time</td>
                      <td>
                        <FormattedDate
                          weekday='short'
                          month='short'
                          day='numeric'
                          year='numeric'
                          value={rental.setByTimeDateTime}
                        />{' '}
                        <FormattedTime
                          value={rental.setByTimeDateTime}
                          timeZone={schedule.timeZone}
                          timeZoneName='short'
                        />
                      </td>
                    </tr>
                  )}
                  {rental.hasSetByTime && rental.setByTimeNotes && (
                    <tr>
                      <td className='notes' colSpan='2'>
                        <p> {rental.setByTimeNotes} </p>
                      </td>
                    </tr>
                  )}
                  {rental.hasStrikeTime && (
                    <tr>
                      <td className='sm withoutBorder'>Strike Time</td>
                      <td>
                        <FormattedDate
                          weekday='short'
                          month='short'
                          day='numeric'
                          year='numeric'
                          value={rental.strikeDateTime}
                        />{' '}
                        <FormattedTime
                          value={rental.strikeDateTime}
                          timeZone={schedule.timeZone}
                          timeZoneName='short'
                        />
                      </td>
                    </tr>
                  )}
                  {rental.hasStrikeTime && rental.strikeTimeNotes && (
                    <tr>
                      <td className='notes' colSpan='2'>
                        <p> {rental.strikeTimeNotes} </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>
            {rental.hasFlip && (
              <div>
                <h4>Flip</h4>
                <table className='grid'>
                  <tbody>
                    <tr>
                      <td />
                      <td className='medSm'>
                        <FormattedDate
                          weekday='short'
                          month='short'
                          day='numeric'
                          year='numeric'
                          value={rental.flipDateTime}
                        />
                      </td>
                      <td>
                        <FormattedTime value={rental.flipDateTime} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {salesperson && (
              <section className='teamMemberPrint'>
                <h4>Team Member</h4>
                <p>{`${salesperson.firstName} ${salesperson.lastName}`}</p>
              </section>
            )}
            <section className='customerPrint'>
              <h4>Contact Info</h4>

              {company && (
                <div>
                  <label>Company</label>
                  <div className='rentalCompany promtArea'>
                    {companyRentalRelationship &&
                      companyRentalRelationship.isPrimaryContact && (
                        <div className='caption'>Primary</div>
                      )}
                    <h2 className='smptxt'>{company.name}</h2>
                    {company.office && (
                      <div className='smptxt'>
                        {formatPhoneNumber(company.office)}
                      </div>
                    )}
                    {company.cell && (
                      <div className='smptxt'>
                        {formatPhoneNumber(company.cell)}
                      </div>
                    )}
                    {company.fax && <div>{formatPhoneNumber(company.fax)}</div>}
                    <div>{company.email}</div>
                    {(company.streetAddress1 ||
                      company.streetAddress2 ||
                      company.postalCode ||
                      company.locale ||
                      company.city ||
                      company.country) && (
                      <div>
                        <div>{company.streetAddress1}</div>
                        <div>{company.streetAddress2}</div>
                        <div>
                          {company.city}, {company.locale} {company.postalCode},{' '}
                          {company.country}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {customerRentalRelationships.length > 0 && (
                <div>
                  <label>Customer</label>
                  {customerRentalRelationships
                    .filter((crr) => crr.isPrimaryContact)
                    .map((crr, index) => this.renderCustomer(crr, index))}
                  {customerRentalRelationships
                    .filter((crr) => !crr.isPrimaryContact)
                    .map((crr, index) => this.renderCustomer(crr, index))}
                </div>
              )}
            </section>
          </div>

          <section
            className={classnames({
              items: true,
              picklist: true,
              full: !(notes && notes.length > 0),
            })}
          >
            {(items.length > 0 ||
              accessories.length > 0 ||
              addons.length > 0 ||
              bundles.length > 0) &&
              sectionType === 'inventory' && (
                <div>
                  <h4>Items</h4>

                  <table
                    className={classnames({
                      grid: true,
                      thumbnail: tileView,
                    })}
                  >
                    <tbody className='rTableHeading'>
                      <tr>
                        <th className='sm'>Qty</th>
                        {tileView && <th className='image'>Item</th>}
                        <th className=''>{!tileView && 'Item'}</th>
                        <th className='tickLabel'>Out</th>
                        <th className='tickLabel'>In</th>
                        <th className='tickLabel'>Missing</th>
                        <th className='tickLabel'>Initial</th>
                      </tr>
                    </tbody>

                    {groupedItems.length > 0 &&
                      groupedItems.map((item, index) => {
                        let inventoryProduct = item.product;
                        return (
                          <tbody key={index}>
                            <tr className='promtArea'>
                              <td className='qty smptxt'>{item.quantity}</td>
                              {tileView && (
                                <td className='image'>
                                  <figure>
                                    {inventoryProduct &&
                                    inventoryProduct.pictures &&
                                    inventoryProduct.pictures[0] ? (
                                      <Imgix
                                        src={
                                          inventoryProduct.pictures[0].imgixUrl
                                        }
                                        alt={item.name}
                                        height={70}
                                        width={124}
                                      />
                                    ) : (
                                      <Items />
                                    )}
                                  </figure>
                                </td>
                              )}
                              <td>
                                {item.subRentals &&
                                  this.renderSubRentalItems(item.subRentals)}
                                {item.name}
                                <span className='wrap'>
                                  {item.itemNotes &&
                                    item.itemNotes?.map((itemNote) => (
                                      <p>{itemNote.message}</p>
                                    ))}
                                </span>
                              </td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='lgTickBox spLftBorder spRgtBorder'>
                                {' '}
                              </td>
                            </tr>
                            {((detailView &&
                              this.hasDimensions(inventoryProduct)) ||
                              item.itemNotes?.length > 0) && (
                              <tr className='noBorder'>
                                {tileView && <td />}
                                <td />
                                <td className='wrap notes'>
                                  {this.hasDimensions(inventoryProduct) &&
                                    this.renderDimensions(inventoryProduct)}
                                </td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='lgTickBox spLftBorder spRgtBorder'></td>
                              </tr>
                            )}
                          </tbody>
                        );
                      })}

                    {groupedAccessories.length > 0 &&
                      groupedAccessories.map((item, index) => {
                        let inventoryProduct = item.accessory;
                        return (
                          <tbody key={index}>
                            <tr className='promtArea'>
                              <td className='qty smptxt'>{item.quantity}</td>
                              {tileView && (
                                <td className='image'>
                                  <figure>
                                    {inventoryProduct &&
                                    inventoryProduct.pictures &&
                                    inventoryProduct.pictures[0] ? (
                                      <Imgix
                                        src={
                                          inventoryProduct.pictures[0].imgixUrl
                                        }
                                        alt={item.name}
                                        height={70}
                                        width={124}
                                      />
                                    ) : (
                                      <Items />
                                    )}
                                  </figure>
                                </td>
                              )}
                              <td>
                                {item.subRentals &&
                                  this.renderSubRentalItems(item.subRentals)}
                                {item.name}
                                <span className='wrap'>
                                  {item.itemNotes &&
                                    item.itemNotes?.map((itemNote) => (
                                      <p>{itemNote.message}</p>
                                    ))}
                                </span>
                              </td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='lgTickBox spLftBorder spRgtBorder'>
                                {' '}
                              </td>
                            </tr>

                            {((detailView &&
                              this.hasDimensions(inventoryProduct)) ||
                              item.itemNotes?.length > 0) && (
                              <tr className='noBorder'>
                                {tileView && <td />}
                                <td />
                                <td className='wrap notes'>
                                  {this.hasDimensions(inventoryProduct) &&
                                    this.renderDimensions(inventoryProduct)}
                                </td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='lgTickBox spLftBorder spRgtBorder'></td>
                              </tr>
                            )}
                          </tbody>
                        );
                      })}

                    {groupedAddons.length > 0 &&
                      groupedAddons.map((item, index) => {
                        let inventoryProduct = item.addOn;
                        return (
                          <tbody key={index}>
                            <tr className='promtArea'>
                              <td className='qty smptxt'>{item.quantity}</td>
                              {tileView && (
                                <td className='image'>
                                  <figure>
                                    {inventoryProduct &&
                                    inventoryProduct.pictures &&
                                    inventoryProduct.pictures[0] ? (
                                      <Imgix
                                        src={
                                          inventoryProduct.pictures[0].imgixUrl
                                        }
                                        alt={item.name}
                                        height={70}
                                        width={124}
                                      />
                                    ) : (
                                      <Items />
                                    )}
                                  </figure>
                                </td>
                              )}
                              <td>
                                {item.subRentals &&
                                  this.renderSubRentalItems(item.subRentals)}
                                {item.name}
                                <span className='wrap'>
                                  {item.itemNotes &&
                                    item.itemNotes?.map((itemNote) => (
                                      <p>{itemNote.message}</p>
                                    ))}
                                </span>
                              </td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='lgTickBox spLftBorder spRgtBorder'>
                                {' '}
                              </td>
                            </tr>

                            {((detailView &&
                              this.hasDimensions(inventoryProduct)) ||
                              item.itemNotes?.length > 0) && (
                              <tr className='noBorder'>
                                {tileView && <td />}
                                <td />
                                <td className='wrap notes'>
                                  {this.hasDimensions(inventoryProduct) &&
                                    this.renderDimensions(inventoryProduct)}
                                </td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='lgTickBox spLftBorder spRgtBorder'></td>
                              </tr>
                            )}
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              )}

            {(items.length > 0 ||
              accessories.length > 0 ||
              addons.length > 0 ||
              bundles.length) &&
              sectionType === 'department' &&
              Object.keys(groupedDepartments).map((key, value) => (
                <div>
                  <h4>{key}</h4>

                  <table
                    className={classnames({
                      grid: true,
                      thumbnail: tileView,
                    })}
                  >
                    <tbody className='rTableHeading'>
                      <tr>
                        <th className='sm'>Qty</th>
                        {tileView && <th className='image'>Item</th>}
                        <th className=''>{!tileView && 'Item'}</th>
                        <th className='tickLabel'>Out</th>
                        <th className='tickLabel'>In</th>
                        <th className='tickLabel'>Missing</th>
                        <th className='tickLabel'>Initial</th>
                      </tr>
                    </tbody>

                    {groupedDepartments[key].map((item, index) => {
                      if (item.bundle) {
                        const inventoryProduct = item.bundle;
                        const bundleItems = item.rentalItems;
                        const bundleAccessories = item.rentalAccessories;
                        const bundleAddOns = item.rentalAddOns;
                        return (
                          <>
                            <tbody key={index}>
                              <tr className='promtArea'>
                                <td className='qty smptxt'>{item.quantity}</td>
                                {tileView && (
                                  <td className='image'>
                                    <figure>
                                      {inventoryProduct &&
                                      inventoryProduct.pictures &&
                                      inventoryProduct.pictures[0] ? (
                                        <Imgix
                                          src={
                                            inventoryProduct.pictures[0]
                                              .imgixUrl
                                          }
                                          alt={item.name}
                                          height={70}
                                          width={124}
                                        />
                                      ) : (
                                        <Items />
                                      )}
                                    </figure>
                                  </td>
                                )}
                                <td>
                                  {item.subRentals &&
                                    this.renderSubRentalItems(item.subRentals)}
                                  {item.name}
                                  <span className='wrap'>
                                    {item.itemNotes &&
                                      item.itemNotes?.map((itemNote) => (
                                        <p>{itemNote.message}</p>
                                      ))}
                                  </span>
                                </td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='lgTickBox spLftBorder spRgtBorder'>
                                  {' '}
                                </td>
                              </tr>

                              {((detailView &&
                                this.hasDimensions(inventoryProduct)) ||
                                item.itemNotes?.length > 0) && (
                                <tr className='noBorder'>
                                  {tileView && <td />}
                                  <td />
                                  <td className='wrap notes'>
                                    {this.hasDimensions(inventoryProduct) &&
                                      this.renderDimensions(inventoryProduct)}
                                  </td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                </tr>
                              )}
                            </tbody>
                            {bundleItems.map((item, index) => {
                              let inventoryProduct = item.product;
                              return (
                                <tbody
                                  key={`${index}a`}
                                  className='pickListBundleItem'
                                >
                                  <tr className='promtArea'>
                                    <td className='sm qty smptxt'>
                                      {item.quantity}
                                    </td>
                                    {tileView && (
                                      <td className='image'>
                                        <figure>
                                          {inventoryProduct &&
                                          inventoryProduct.pictures &&
                                          inventoryProduct.pictures[0] ? (
                                            <Imgix
                                              src={
                                                inventoryProduct.pictures[0]
                                                  .imgixUrl
                                              }
                                              alt={item.name}
                                              height={70}
                                              width={124}
                                            />
                                          ) : (
                                            <Items />
                                          )}
                                        </figure>
                                      </td>
                                    )}
                                    <td className='medSm'>
                                      {item.subRentals &&
                                        this.renderSubRentalItems(
                                          item.subRentals
                                        )}
                                      {item.name}
                                    </td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                  </tr>

                                  {((detailView &&
                                    this.hasDimensions(inventoryProduct)) ||
                                    item.itemNotes?.length > 0) && (
                                    <tr className='noBorder'>
                                      {tileView && <td />}
                                      <td />
                                      <td className='wrap notes'>
                                        {this.hasDimensions(inventoryProduct) &&
                                          this.renderDimensions(
                                            inventoryProduct
                                          )}
                                      </td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                    </tr>
                                  )}
                                </tbody>
                              );
                            })}

                            {bundleAccessories.length > 0 &&
                              bundleAccessories.map((item, index) => {
                                let inventoryProduct = item.accessory;
                                return (
                                  <tbody
                                    key={`access${index}a`}
                                    className='pickListBundleAccessory'
                                  >
                                    <tr className='promtArea'>
                                      <td className='sm qty smptxt'>
                                        {item.quantity}
                                      </td>
                                      {tileView && (
                                        <td className='image'>
                                          <figure>
                                            {inventoryProduct &&
                                            inventoryProduct.pictures &&
                                            inventoryProduct.pictures[0] ? (
                                              <Imgix
                                                src={
                                                  inventoryProduct.pictures[0]
                                                    .imgixUrl
                                                }
                                                alt={item.name}
                                                height={70}
                                                width={124}
                                              />
                                            ) : (
                                              <Items />
                                            )}
                                          </figure>
                                        </td>
                                      )}
                                      <td className='medSm'>
                                        {item.subRentals &&
                                          this.renderSubRentalItems(
                                            item.subRentals
                                          )}
                                        {item.name}
                                      </td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                    </tr>

                                    {((detailView &&
                                      this.hasDimensions(inventoryProduct)) ||
                                      item.itemNotes?.length > 0) && (
                                      <tr className='noBorder'>
                                        {tileView && <td />}
                                        <td />
                                        <td className='wrap notes'>
                                          {this.hasDimensions(
                                            inventoryProduct
                                          ) &&
                                            this.renderDimensions(
                                              inventoryProduct
                                            )}
                                        </td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                      </tr>
                                    )}
                                  </tbody>
                                );
                              })}

                            {bundleAddOns.length > 0 &&
                              bundleAddOns.map((item, index) => {
                                let inventoryProduct = item.addOn;
                                return (
                                  <tbody
                                    key={`addon${index}a`}
                                    className='pickListBundleAddOn'
                                  >
                                    <tr className='promtArea'>
                                      <td className='sm smptxt'>
                                        {item.warehousedAmount}
                                      </td>
                                      {tileView && (
                                        <td className='image'>
                                          <figure>
                                            {inventoryProduct &&
                                            inventoryProduct.pictures &&
                                            inventoryProduct.pictures[0] ? (
                                              <Imgix
                                                src={
                                                  inventoryProduct.pictures[0]
                                                    .imgixUrl
                                                }
                                                alt={item.name}
                                                height={70}
                                                width={124}
                                              />
                                            ) : (
                                              <Items />
                                            )}
                                          </figure>
                                        </td>
                                      )}
                                      <td className='medSm'>
                                        {item.subRentals &&
                                          this.renderSubRentalItems(
                                            item.subRentals
                                          )}
                                        {item.name}
                                      </td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                    </tr>

                                    {((detailView &&
                                      this.hasDimensions(inventoryProduct)) ||
                                      item.itemNotes?.length > 0) && (
                                      <tr className='noBorder'>
                                        {tileView && <td />}
                                        <td />
                                        <td className='wrap notes'>
                                          {this.hasDimensions(
                                            inventoryProduct
                                          ) &&
                                            this.renderDimensions(
                                              inventoryProduct
                                            )}
                                        </td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                      </tr>
                                    )}
                                  </tbody>
                                );
                              })}
                          </>
                        );
                      } else {
                        let inventoryProduct =
                          item.product || item.addOn || item.accessory;

                        return (
                          <tbody key={index}>
                            <tr className='promtArea'>
                              <td className='qty smptxt'>{item.quantity}</td>
                              {tileView && (
                                <td className='image'>
                                  <figure>
                                    {inventoryProduct &&
                                    inventoryProduct.pictures &&
                                    inventoryProduct.pictures[0] ? (
                                      <Imgix
                                        src={
                                          inventoryProduct.pictures[0].imgixUrl
                                        }
                                        alt={item.name}
                                        height={70}
                                        width={124}
                                      />
                                    ) : (
                                      <Items />
                                    )}
                                  </figure>
                                </td>
                              )}
                              <td>
                                {item.subRentals &&
                                  this.renderSubRentalItems(item.subRentals)}
                                {item.name}
                                <span className='wrap'>
                                  {item.itemNotes &&
                                    item.itemNotes?.map((itemNote) => (
                                      <p>{itemNote.message}</p>
                                    ))}
                                </span>
                              </td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='lgTickBox spLftBorder spRgtBorder'>
                                {' '}
                              </td>
                            </tr>
                            {((detailView &&
                              this.hasDimensions(inventoryProduct)) ||
                              item.itemNotes?.length > 0) && (
                              <tr className='noBorder'>
                                {tileView && <td />}
                                <td />
                                <td className='wrap notes'>
                                  {this.hasDimensions(inventoryProduct) &&
                                    this.renderDimensions(inventoryProduct)}
                                </td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='lgTickBox spLftBorder spRgtBorder'></td>
                              </tr>
                            )}
                          </tbody>
                        );
                      }
                    })}
                  </table>
                </div>
              ))}

            {(items.length > 0 ||
              accessories.length > 0 ||
              addons.length > 0 ||
              bundles.length > 0) &&
              sectionType === 'category' &&
              Object.keys(groupedCategories).map((key, value) => (
                <div>
                  <h4>{key}</h4>

                  <table
                    className={classnames({
                      grid: true,
                      thumbnail: tileView,
                    })}
                  >
                    <tbody className='rTableHeading'>
                      <tr>
                        <th className='sm'>Qty</th>
                        {tileView && <th className='image'>Item</th>}
                        <th className=''>{!tileView && 'Item'}</th>
                        <th className='tickLabel'>Out</th>
                        <th className='tickLabel'>In</th>
                        <th className='tickLabel'>Missing</th>
                        <th className='tickLabel'>Initial</th>
                      </tr>
                    </tbody>

                    {groupedCategories[key].map((item, index) => {
                      if (item.bundle) {
                        const inventoryProduct = item.bundle;
                        const bundleItems = item.rentalItems;
                        const bundleAccessories = item.rentalAccessories;
                        const bundleAddOns = item.rentalAddOns;
                        return (
                          <>
                            <tbody key={index}>
                              <tr className='promtArea'>
                                <td className='qty smptxt'>{item.quantity}</td>
                                {tileView && (
                                  <td className='image'>
                                    <figure>
                                      {inventoryProduct &&
                                      inventoryProduct.pictures &&
                                      inventoryProduct.pictures[0] ? (
                                        <Imgix
                                          src={
                                            inventoryProduct.pictures[0]
                                              .imgixUrl
                                          }
                                          alt={item.name}
                                          height={70}
                                          width={124}
                                        />
                                      ) : (
                                        <Items />
                                      )}
                                    </figure>
                                  </td>
                                )}
                                <td>
                                  {item.subRentals &&
                                    this.renderSubRentalItems(item.subRentals)}
                                  {item.name}
                                  <span className='wrap'>
                                    {item.itemNotes &&
                                      item.itemNotes?.map((itemNote) => (
                                        <p>{itemNote.message}</p>
                                      ))}
                                  </span>
                                </td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='lgTickBox spLftBorder spRgtBorder'>
                                  {' '}
                                </td>
                              </tr>

                              {((detailView &&
                                this.hasDimensions(inventoryProduct)) ||
                                item.itemNotes?.length > 0) && (
                                <tr className='noBorder'>
                                  {tileView && <td />}
                                  <td />
                                  <td className='wrap notes'>
                                    {this.hasDimensions(inventoryProduct) &&
                                      this.renderDimensions(inventoryProduct)}
                                  </td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                </tr>
                              )}
                            </tbody>
                            {bundleItems.map((item, index) => {
                              let inventoryProduct = item.product;
                              return (
                                <tbody
                                  key={`${index}a`}
                                  className='pickListBundleItem'
                                >
                                  <tr className='promtArea'>
                                    <td className='sm qty smptxt'>
                                      {item.quantity}
                                    </td>
                                    {tileView && (
                                      <td className='image'>
                                        <figure>
                                          {inventoryProduct &&
                                          inventoryProduct.pictures &&
                                          inventoryProduct.pictures[0] ? (
                                            <Imgix
                                              src={
                                                inventoryProduct.pictures[0]
                                                  .imgixUrl
                                              }
                                              alt={item.name}
                                              height={70}
                                              width={124}
                                            />
                                          ) : (
                                            <Items />
                                          )}
                                        </figure>
                                      </td>
                                    )}
                                    <td className='medSm'>
                                      {item.subRentals &&
                                        this.renderSubRentalItems(
                                          item.subRentals
                                        )}
                                      {item.name}
                                    </td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                  </tr>

                                  {((detailView &&
                                    this.hasDimensions(inventoryProduct)) ||
                                    item.itemNotes?.length > 0) && (
                                    <tr className='noBorder'>
                                      {tileView && <td />}
                                      <td />
                                      <td className='wrap notes'>
                                        {this.hasDimensions(inventoryProduct) &&
                                          this.renderDimensions(
                                            inventoryProduct
                                          )}
                                      </td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                    </tr>
                                  )}
                                </tbody>
                              );
                            })}

                            {bundleAccessories.length > 0 &&
                              bundleAccessories.map((item, index) => {
                                let inventoryProduct = item.accessory;
                                return (
                                  <tbody
                                    key={`access${index}a`}
                                    className='pickListBundleAccessory'
                                  >
                                    <tr className='promtArea'>
                                      <td className='sm qty smptxt'>
                                        {item.quantity}
                                      </td>
                                      {tileView && (
                                        <td className='image'>
                                          <figure>
                                            {inventoryProduct &&
                                            inventoryProduct.pictures &&
                                            inventoryProduct.pictures[0] ? (
                                              <Imgix
                                                src={
                                                  inventoryProduct.pictures[0]
                                                    .imgixUrl
                                                }
                                                alt={item.name}
                                                height={70}
                                                width={124}
                                              />
                                            ) : (
                                              <Items />
                                            )}
                                          </figure>
                                        </td>
                                      )}
                                      <td className='medSm'>
                                        {item.subRentals &&
                                          this.renderSubRentalItems(
                                            item.subRentals
                                          )}
                                        {item.name}
                                      </td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                    </tr>

                                    {((detailView &&
                                      this.hasDimensions(inventoryProduct)) ||
                                      item.itemNotes?.length > 0) && (
                                      <tr className='noBorder'>
                                        {tileView && <td />}
                                        <td />
                                        <td className='wrap notes'>
                                          {this.hasDimensions(
                                            inventoryProduct
                                          ) &&
                                            this.renderDimensions(
                                              inventoryProduct
                                            )}
                                        </td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                      </tr>
                                    )}
                                  </tbody>
                                );
                              })}

                            {bundleAddOns.length > 0 &&
                              bundleAddOns.map((item, index) => {
                                let inventoryProduct = item.addOn;
                                return (
                                  <tbody
                                    key={`addon${index}a`}
                                    className='pickListBundleAddOn'
                                  >
                                    <tr className='promtArea'>
                                      <td className='sm smptxt'>
                                        {item.warehousedAmount}
                                      </td>
                                      {tileView && (
                                        <td className='image'>
                                          <figure>
                                            {inventoryProduct &&
                                            inventoryProduct.pictures &&
                                            inventoryProduct.pictures[0] ? (
                                              <Imgix
                                                src={
                                                  inventoryProduct.pictures[0]
                                                    .imgixUrl
                                                }
                                                alt={item.name}
                                                height={70}
                                                width={124}
                                              />
                                            ) : (
                                              <Items />
                                            )}
                                          </figure>
                                        </td>
                                      )}
                                      <td className='medSm'>
                                        {item.subRentals &&
                                          this.renderSubRentalItems(
                                            item.subRentals
                                          )}
                                        {item.name}
                                      </td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='smTickBox spLftBorder'></td>
                                      <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                    </tr>

                                    {((detailView &&
                                      this.hasDimensions(inventoryProduct)) ||
                                      item.itemNotes?.length > 0) && (
                                      <tr className='noBorder'>
                                        {tileView && <td />}
                                        <td />
                                        <td className='wrap notes'>
                                          {this.hasDimensions(
                                            inventoryProduct
                                          ) &&
                                            this.renderDimensions(
                                              inventoryProduct
                                            )}
                                        </td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='smTickBox spLftBorder'></td>
                                        <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                      </tr>
                                    )}
                                  </tbody>
                                );
                              })}
                          </>
                        );
                      } else {
                        let inventoryProduct =
                          item.product || item.addOn || item.accessory;

                        return (
                          <tbody key={index}>
                            <tr className='promtArea'>
                              <td className='qty smptxt'>{item.quantity}</td>
                              {tileView && (
                                <td className='image'>
                                  <figure>
                                    {inventoryProduct &&
                                    inventoryProduct.pictures &&
                                    inventoryProduct.pictures[0] ? (
                                      <Imgix
                                        src={
                                          inventoryProduct.pictures[0].imgixUrl
                                        }
                                        alt={item.name}
                                        height={70}
                                        width={124}
                                      />
                                    ) : (
                                      <Items />
                                    )}
                                  </figure>
                                </td>
                              )}
                              <td>
                                {item.subRentals &&
                                  this.renderSubRentalItems(item.subRentals)}
                                {item.name}
                                <span className='wrap'>
                                  {item.itemNotes &&
                                    item.itemNotes?.map((itemNote) => (
                                      <p>{itemNote.message}</p>
                                    ))}
                                </span>
                              </td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='lgTickBox spLftBorder spRgtBorder'>
                                {' '}
                              </td>
                            </tr>
                            {((detailView &&
                              this.hasDimensions(inventoryProduct)) ||
                              item.itemNotes?.length > 0) && (
                              <tr className='noBorder'>
                                {tileView && <td />}
                                <td />
                                <td className='wrap notes'>
                                  {this.hasDimensions(inventoryProduct) &&
                                    this.renderDimensions(inventoryProduct)}
                                </td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='lgTickBox spLftBorder spRgtBorder'></td>
                              </tr>
                            )}
                          </tbody>
                        );
                      }
                    })}
                  </table>
                </div>
              ))}

            {groupedBundles.length > 0 && sectionType === 'inventory' && (
              <div className='bundles'>
                <h4>Bundles</h4>
                <table
                  className={classnames({
                    grid: true,
                    thumbnail: tileView,
                  })}
                >
                  <tbody className='rTableHeading'>
                    <tr>
                      <th className='sm'>Qty</th>
                      {tileView && <th className='image' />}
                      <th className='medSm'>Bundles</th>
                      <th className='action'></th>
                      <th className='action'></th>
                      <th className='action'></th>
                      <th className='action'></th>
                    </tr>
                  </tbody>
                  {groupedBundles.map((item, index) => {
                    const inventoryProduct = item.bundle;
                    const bundleItems = item.rentalItems;
                    const bundleAccessories = item.rentalAccessories;
                    const bundleAddOns = item.rentalAddOns;
                    return (
                      <>
                        <tbody key={index}>
                          <tr className='promtArea'>
                            <td className='qty smptxt'>{item.quantity}</td>
                            {tileView && (
                              <td className='image'>
                                <figure>
                                  {inventoryProduct &&
                                  inventoryProduct.pictures &&
                                  inventoryProduct.pictures[0] ? (
                                    <Imgix
                                      src={
                                        inventoryProduct.pictures[0].imgixUrl
                                      }
                                      alt={item.name}
                                      height={70}
                                      width={124}
                                    />
                                  ) : (
                                    <Items />
                                  )}
                                </figure>
                              </td>
                            )}
                            <td>
                              {item.subRentals &&
                                this.renderSubRentalItems(item.subRentals)}
                              {item.name}
                              <span className='wrap'>
                                {item.itemNotes &&
                                  item.itemNotes?.map((itemNote) => (
                                    <p>{itemNote.message}</p>
                                  ))}
                              </span>
                            </td>
                            <td className='smTickBox spLftBorder'></td>
                            <td className='smTickBox spLftBorder'></td>
                            <td className='smTickBox spLftBorder'></td>
                            <td className='lgTickBox spLftBorder spRgtBorder'>
                              {' '}
                            </td>
                          </tr>

                          {((detailView &&
                            this.hasDimensions(inventoryProduct)) ||
                            item.itemNotes?.length > 0) && (
                            <tr className='noBorder'>
                              {tileView && <td />}
                              <td />
                              <td className='wrap notes'>
                                {this.hasDimensions(inventoryProduct) &&
                                  this.renderDimensions(inventoryProduct)}
                              </td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='smTickBox spLftBorder'></td>
                              <td className='lgTickBox spLftBorder spRgtBorder'></td>
                            </tr>
                          )}
                        </tbody>
                        {bundleItems.map((item, index) => {
                          let inventoryProduct = item.product;
                          return (
                            <tbody
                              key={`${index}a`}
                              className='pickListBundleItem'
                            >
                              <tr className='promtArea'>
                                <td className='sm qty smptxt'>
                                  {item.quantity}
                                </td>
                                {tileView && (
                                  <td className='image'>
                                    <figure>
                                      {inventoryProduct &&
                                      inventoryProduct.pictures &&
                                      inventoryProduct.pictures[0] ? (
                                        <Imgix
                                          src={
                                            inventoryProduct.pictures[0]
                                              .imgixUrl
                                          }
                                          alt={item.name}
                                          height={70}
                                          width={124}
                                        />
                                      ) : (
                                        <Items />
                                      )}
                                    </figure>
                                  </td>
                                )}
                                <td className='medSm'>
                                  {item.subRentals &&
                                    this.renderSubRentalItems(item.subRentals)}
                                  {item.name}
                                  <span className='wrap'>
                                    {item.itemNotes &&
                                      item.itemNotes?.map((itemNote) => (
                                        <p>{itemNote.message}</p>
                                      ))}
                                  </span>
                                </td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='smTickBox spLftBorder'></td>
                                <td className='lgTickBox spLftBorder spRgtBorder'></td>
                              </tr>

                              {((detailView &&
                                this.hasDimensions(inventoryProduct)) ||
                                item.itemNotes?.length > 0) && (
                                <tr className='noBorder'>
                                  {tileView && <td />}
                                  <td />
                                  <td className='wrap notes'>
                                    {this.hasDimensions(inventoryProduct) &&
                                      this.renderDimensions(inventoryProduct)}
                                  </td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                </tr>
                              )}
                            </tbody>
                          );
                        })}

                        {bundleAccessories.length > 0 &&
                          bundleAccessories.map((item, index) => {
                            let inventoryProduct = item.accessory;
                            return (
                              <tbody
                                key={`access${index}a`}
                                className='pickListBundleAccessory'
                              >
                                <tr className='promtArea'>
                                  <td className='sm qty smptxt'>
                                    {item.quantity}
                                  </td>
                                  {tileView && (
                                    <td className='image'>
                                      <figure>
                                        {inventoryProduct &&
                                        inventoryProduct.pictures &&
                                        inventoryProduct.pictures[0] ? (
                                          <Imgix
                                            src={
                                              inventoryProduct.pictures[0]
                                                .imgixUrl
                                            }
                                            alt={item.name}
                                            height={70}
                                            width={124}
                                          />
                                        ) : (
                                          <Items />
                                        )}
                                      </figure>
                                    </td>
                                  )}
                                  <td className='medSm'>
                                    {item.subRentals &&
                                      this.renderSubRentalItems(
                                        item.subRentals
                                      )}
                                    {item.name}
                                    <span className='wrap'>
                                      {item.itemNotes &&
                                        item.itemNotes?.map((itemNote) => (
                                          <p>{itemNote.message}</p>
                                        ))}
                                    </span>
                                  </td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                </tr>

                                {((detailView &&
                                  this.hasDimensions(inventoryProduct)) ||
                                  item.itemNotes?.length > 0) && (
                                  <tr className='noBorder'>
                                    {tileView && <td />}
                                    <td />
                                    <td className='wrap notes'>
                                      {this.hasDimensions(inventoryProduct) &&
                                        this.renderDimensions(inventoryProduct)}
                                    </td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                  </tr>
                                )}
                              </tbody>
                            );
                          })}

                        {bundleAddOns.length > 0 &&
                          bundleAddOns.map((item, index) => {
                            let inventoryProduct = item.addOn;
                            return (
                              <tbody
                                key={`addon${index}a`}
                                className='pickListBundleAddOn'
                              >
                                <tr className='promtArea'>
                                  <td className='sm smptxt'>
                                    {item.warehousedAmount}
                                  </td>
                                  {tileView && (
                                    <td className='image'>
                                      <figure>
                                        {inventoryProduct &&
                                        inventoryProduct.pictures &&
                                        inventoryProduct.pictures[0] ? (
                                          <Imgix
                                            src={
                                              inventoryProduct.pictures[0]
                                                .imgixUrl
                                            }
                                            alt={item.name}
                                            height={70}
                                            width={124}
                                          />
                                        ) : (
                                          <Items />
                                        )}
                                      </figure>
                                    </td>
                                  )}
                                  <td className='medSm'>
                                    {item.subRentals &&
                                      this.renderSubRentalItems(
                                        item.subRentals
                                      )}
                                    {item.name}
                                    <span className='wrap'>
                                      {item.itemNotes &&
                                        item.itemNotes?.map((itemNote) => (
                                          <p>{itemNote.message}</p>
                                        ))}
                                    </span>
                                  </td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='smTickBox spLftBorder'></td>
                                  <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                </tr>

                                {((detailView &&
                                  this.hasDimensions(inventoryProduct)) ||
                                  item.itemNotes?.length > 0) && (
                                  <tr className='noBorder'>
                                    {tileView && <td />}
                                    <td />
                                    <td className='wrap notes'>
                                      {this.hasDimensions(inventoryProduct) &&
                                        this.renderDimensions(inventoryProduct)}
                                    </td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='smTickBox spLftBorder'></td>
                                    <td className='lgTickBox spLftBorder spRgtBorder'></td>
                                  </tr>
                                )}
                              </tbody>
                            );
                          })}
                      </>
                    );
                  })}
                </table>
              </div>
            )}

            {staffs.length > 0 && (
              <div className='staff'>
                <h4>Staff</h4>
                <table className='grid'>
                  <tbody className='rTableHeading'>
                    <tr>
                      <th>Staff</th>
                      <th />
                      <th>Time In</th>
                      <th>Time Out</th>
                    </tr>
                  </tbody>
                  {staffs.map((staff, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>{staff.name}</td>
                        <td>
                          {staff.staffPricing
                            ? staff.staffPricing.pricingType
                            : 'Custom Fixed Fee'}
                        </td>
                        {staff.rentalStaffMembers.length > 0 ? (
                          <td />
                        ) : (
                          <td>
                            <FormattedDate
                              value={staff.staffSchedule.inTime}
                              month='numeric'
                              year='numeric'
                              day='numeric'
                            />{' '}
                            <FormattedTime value={staff.staffSchedule.inTime} />{' '}
                          </td>
                        )}
                        {staff.rentalStaffMembers.length > 0 ? (
                          <td />
                        ) : (
                          <td>
                            <FormattedDate
                              value={staff.staffSchedule.outTime}
                              month='numeric'
                              year='numeric'
                              day='numeric'
                            />{' '}
                            <FormattedTime
                              value={staff.staffSchedule.outTime}
                            />
                          </td>
                        )}
                      </tr>
                      {staff.rentalStaffMembers.map((rentalStaffMember) => (
                        <tr key={rentalStaffMember.id} className='noBorder'>
                          <td>{rentalStaffMember.name}</td>
                          <td>
                            {rentalStaffMember.staffMember.cell &&
                              rentalStaffMember.staffMember.cell.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                '$1-$2-$3'
                              )}
                          </td>
                          <td>
                            <FormattedDate
                              value={rentalStaffMember.staffSchedule.inTime}
                              month='numeric'
                              year='numeric'
                              day='numeric'
                            />{' '}
                            <FormattedTime
                              value={rentalStaffMember.staffSchedule.inTime}
                            />{' '}
                          </td>
                          <td>
                            <FormattedDate
                              value={rentalStaffMember.staffSchedule.outTime}
                              month='numeric'
                              year='numeric'
                              day='numeric'
                            />{' '}
                            <FormattedTime
                              value={rentalStaffMember.staffSchedule.outTime}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
                </table>
              </div>
            )}
          </section>
          {notes && notes.length > 0 && (
            <section className='notes'>
              <h4>Notes</h4>
              {notes.map((note) => {
                if (!note.visibleToOperations === false)
                  return (
                    <div key={note.id}>
                      <h6>
                        <FormattedDate
                          value={note.createdAt}
                          year='numeric'
                          month='short'
                          day='numeric'
                        />
                        {note.employee && (
                          <span className='highlight'>
                            {note.employee.firstName} {note.employee.lastName}
                          </span>
                        )}
                      </h6>
                      <p
                        className='editedText'
                        dangerouslySetInnerHTML={{
                          __html: note.message,
                        }}
                      />
                    </div>
                  );
              })}
            </section>
          )}
          <div className='printSignature'>
            <div className='picklistSignaturesGrid'>
              <label className='title'>Check Out</label>
              <div className='signatureContainer signatureLine'>
                {rental.checkOutSignature ? (
                  <img
                    className='signatureImage'
                    src={
                      customerSignatureCheckOut.signature ||
                      rental.checkOutSignature
                    }
                    alt='customerCheckOutSignature'
                  />
                ) : (
                  <label className='line lg'></label>
                )}

                <br />
                <div className='signatureLabels'>
                  <label>Signature</label>
                  <label className='signatureDatetime'>
                    {dateToFormattedUTCString(
                      customerSignatureCheckOut.updatedAt
                    )}{' '}
                  </label>
                </div>
              </div>
              <label className='line signatureDate'>Date</label>
            </div>
            <br />
            <div className='picklistSignaturesGrid'>
              <label className='title'>Check In</label>
              <div className='signatureContainer signatureLine'>
                {rental.checkInSignature ? (
                  <img
                    className='signatureImage'
                    src={rental.checkInSignature}
                    alt='CustomerCheckInsignature'
                  />
                ) : (
                  <label className='signatureImage'></label>
                )}

                <br />
                <div className='signatureLabels'>
                  <label>Signature</label>
                  <label className='signatureDatetime'>
                    {dateToFormattedUTCString(
                      customerSignatureCheckIn.updatedAt
                    )}{' '}
                  </label>
                </div>
              </div>
              <label className='line signatureDate'>Date</label>
            </div>
            <p>
              {location.picklistClause
                ? location.picklistClause.description
                : ''}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { rental } = state.rental;
  const { business } = state.business;
  const { location } = state.location;
  const { itemNotesView } = state.products;
  return { user, rental, business, location, itemNotesView };
};

export default connect(mapStateToProps, { fetchRentalIfNeeded })(PrintPickList);
